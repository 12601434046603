import Carousel from 'react-bootstrap/Carousel';
import { useHistory } from 'react-router-dom';

export function SliderHome() {

  const history = useHistory();

  function handleGoToProdutos(routeName) {
    history.push({
      pathname: '/produtos',
      state: { nomedacategoria: routeName }
    });
  }

  return (
    <div className="container-fluid" style={{maxHeight:'90vh', overflow: 'auto'}}>

      <div className="row" style={{paddingTop:10}}>

        <div className="col-sm-12">
        <Carousel>
          <Carousel.Item interval={3000}>
            <img style={{borderRadius:10}}
              className="d-block w-100"
              src="https://mmvdistribuidora.com.br/api/slide/1.png"
              alt="Lançamento 2022"
              onClick={()=>{handleGoToProdutos("VERCELLI-BIC")}}
            />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img style={{borderRadius:10}}
              className="d-block w-100"
              src="https://mmvdistribuidora.com.br/api/slide/2.png"
              alt="Preços Imbatíveis"
              onClick={()=>{handleGoToProdutos(null)}}
            />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img style={{borderRadius:10}}
              className="d-block w-100"
              src="https://mmvdistribuidora.com.br/api/slide/3.png"
              alt="Promoção Junho/22"
              onClick={()=>{handleGoToProdutos(null)}}
            />
          </Carousel.Item>
        </Carousel>
        </div>

      </div>

      <div className="row" style={{paddingTop:20}}>

        <div className="col-sm-3">
            <img style={{borderRadius:10, cursor:'pointer'}}
              className="d-block w-100"
              src="https://mmvdistribuidora.com.br/api/slide/mini-1.png"
              alt="Pneus e Camaras"
              onClick={()=>{handleGoToProdutos("CAMARA")}}
            />
        </div>
        <div className="col-sm-3">
            <img style={{borderRadius:10, cursor:'pointer'}}
              className="d-block w-100"
              src="https://mmvdistribuidora.com.br/api/slide/mini-2.png"
              alt="Linha HighEnd"
              onClick={()=>{handleGoToProdutos(null)}}
            />
        </div>
        <div className="col-sm-3">
            <img style={{borderRadius:10, cursor:'pointer'}}
              className="d-block w-100"
              src="https://mmvdistribuidora.com.br/api/slide/mini-3.png"
              alt="Produtos MMV"
              onClick={()=>{handleGoToProdutos(null)}}
            />
        </div>
        <div className="col-sm-3">
            <img style={{borderRadius:10, cursor:'pointer'}}
              className="d-block w-100"
              src="https://mmvdistribuidora.com.br/api/slide/mini-4.png"
              alt="Produtos Vercelli"
              onClick={()=>{handleGoToProdutos("VERCELLI")}}
            />
        </div>

      </div> 

    </div>
  );
}
