import React, { useState, useEffect, useMemo } from 'react';
// eslint-disable-next-line
import { Container, Table, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import { database } from '../../services/firebase';
import { FaUser, FaUserPlus } from 'react-icons/fa';

import './clientesanalise.css'

export function ClientesAnalise() {

  const history = useHistory();

  const [clientesList, setClientesList] = useState([]);
  const [buscar, setBuscar] = useState('');
  const [search, setSearch] = useState('');
  const [buscarcontato, setBuscarContato] = useState('');
  const [buscarEmail, setBuscarEmail] = useState('');

  useEffect(() => {  
    const clientesRef = database.ref(`/usuarios/`);
      clientesRef.on('value', (snapshot) => {      
        const clientes = snapshot.val();
        const clientesList = [];
        for (let id in clientes) {
          clientesList.push ({ id, ...clientes[id] });
        }
        setClientesList(clientesList);
      });
    return () => {
      clientesRef.off('value');
    }
  }, []);

  const permissao = cliente => cliente.permissao === 'cliente';
  const permissaocliente = clientesList.filter(permissao);

  const listaanalise = cliente => cliente.analise === false;
  const analisedeclientes = permissaocliente.filter(listaanalise);

  analisedeclientes.sort(function (a, b) {
    return (a.cod > b.cod) ? 1 : ((b.cod > a.cod) ? -1 : 0);
  });

  analisedeclientes.reverse();


  const maisfiltrados = useMemo(() => {  
    if (!buscar) return analisedeclientes;
   // eslint-disable-next-line
   return analisedeclientes.filter(cliente => cliente?.cod == buscar);   
  }, [buscar, analisedeclientes]);

  const filtradospornome = useMemo(() => {
      if (!search) return maisfiltrados;
      return maisfiltrados.filter((cliente) => {
        return (
            cliente?.razaosocial?.toUpperCase().includes(search.toUpperCase())
        );
      });
  }, [search, maisfiltrados]);

  const filtradosporcontato = useMemo(() => {
    if (!buscarcontato) return filtradospornome;
    return filtradospornome.filter((cliente) => {
      return (
          cliente.nome.toUpperCase().includes(buscarcontato.toUpperCase())
      );
    });
  }, [buscarcontato, filtradospornome]);

  const filtradosporemail = useMemo(() => {
    if (!buscarEmail) return filtradosporcontato;
    return filtradosporcontato.filter((cliente) => {
      return (
        cliente.email.toLowerCase().includes(buscarEmail.toLowerCase())
      );
    });
  }, [buscarEmail, filtradosporcontato]);


  async function handleActive(routeName) {
    enviaremail(routeName);
    const clienteRef = database.ref(`/usuarios/`).child(`${routeName}/`);    
    await clienteRef.update({
      analise:true
    });
  }

  async function enviaremail(mail, name) {
    await api.get(`/send-email?email=${mail}&name=${name}`);
  }

  async function handleRemove(routeName) {

    const optionsData = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      timeZone: 'America/Sao_Paulo',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    const date = new Intl.DateTimeFormat([], optionsData);
    const timestamp = date.format(new Date())
    
    const clienteRef = database.ref(`/usuarios/`).child(`${routeName}/`);
    await clienteRef.update({
      analise:'removido',
      removido:timestamp
    });
  }

  useEffect(() => {
    let t = setTimeout(() => {
      if(analisedeclientes.length === 0) {
        history.push('/clientes')
      }
    }, 500);

    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [analisedeclientes]);


  return(

      <>
        <div className="container-fluid" id="filtrinhos">

          <div className="row">
            <div className="col-sm-2 col-md-2 col-xs-12 col-lg-3">
              <div className="inputfiltroclientes">
                <input  id="inputfiltroclientes"
                  type="text" 
                  placeholder="código" 
                  disabled = {false}
                  onChange={event => setBuscar(event.target.value)}
                  value={buscar}
                  />                          
              </div>
            </div>

            <div className="col-sm-3 col-md-3 col-xs-12 col-lg-3">
              <div className="inputfiltroclientes">
                <input id="inputfiltroclientes"
                  type="text" 
                  placeholder="razão social" 
                  disabled = {false}                
                  onChange={event => setSearch(event.target.value.toUpperCase())}
                  value={search}
                  />                          
              </div>
            </div>

            <div className="col-sm-3 col-md-3 col-xs-12 col-lg-3">
              <div className="inputfiltroclientes">
                <input  id="inputfiltroclientes"
                  type="text" 
                  placeholder="cliente" 
                  disabled = {false}                
                  onChange={event => setBuscarContato(event.target.value.toUpperCase())}
                  value={buscarcontato}
                  />                          
              </div>
            </div>

            <div className="col-sm-3 col-md-3 col-xs-12 col-lg-3">
              <div className="inputfiltroclientes">
                <input id="inputfiltroclientes"
                  type="text" 
                  placeholder="e-mail do cliente" 
                  disabled = {false}                
                  onChange={event => setBuscarEmail(event.target.value.toLowerCase())}
                  value={buscarEmail}
                  />                          
              </div>
            </div>

          </div>

        </div>
      
        <Container fluid style={{marginTop:20}} id="containerclienteanalise">   
          
        {filtradosporemail.map((cliente, index) => (
          <div key={cliente?.id} id="cardclienteanalise">

            <Row>
              <Col>
                <p>Código: {cliente?.cod} </p>        
              </Col>
              <Col>
                <p>Criado: {cliente?.createat}</p>        
              </Col>
            </Row>

            <Row>
              <Col>
                <p>CNPJ: {cliente?.cnpj}</p>        
              </Col>
            </Row>

            <Row className="colunarazao">
              <Col>
              <p>Razão: {cliente?.razaosocial}</p>        
              </Col>
            </Row>

            <Row>
              <Col>
                <p>Nome: {cliente?.nome}</p>       
              </Col>
            </Row>

            <Row>
              <Col>
                <p>{cliente?.email}</p>      
              </Col>
            </Row>

            <Row>
              <Col>
                <p>Celular: {cliente?.celular}</p>       
              </Col>
            </Row>

            <Row>
              <Col>
              <p>Cidade: {cliente?.endereco?.cidade}</p>       
              </Col>
            </Row>

            <Row>
              <Col>
                <p>Último Login: {cliente?.ultimologin}</p>       
              </Col>
            </Row>

            <Row className="actionbtn">
              <Col>
              <button type="button" className="btnAprovar" 
                onClick={()=>{handleActive(cliente.uid); enviaremail(cliente.email, cliente.nome)}}><FaUserPlus/><span> Aprovar</span>
              </button>
              </Col>
              <Col>
              <button type="button" className="btnReprovar" 
                onClick={()=>{handleRemove(cliente.uid)}}><FaUser/><span> Remover</span>
              </button>    
              </Col>
            </Row>         

          </div>
        ))}

        </Container>

      </>

  );

}



