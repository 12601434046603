import React from 'react';

import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { MenuLeft } from '../../components/MenuLeft';
import { MenuLeftCliente } from '../../components/MenuLeftCliente';
import { MenuRight } from '../../components/MenuRight';
import { MenuRightMobile } from '../../components/MenuRightMobile';

import './carrinho.css'

export function Carrinho() {

  const { usuario } = useUsuarios();

  return(
    <>
      <Header/>
        <div id="page-carrinho">

          <div id="lateral">
            {usuario?.permissao === 'superUser' ? <MenuLeft/> : <MenuLeftCliente/>}
          </div>
          
            {usuario?.analise === true && 
              <div id="principal-carrinho">
                <MenuRight/>
              </div>
            }

            {usuario?.analise === true && 
              <div id="principal-carrinho-mobile">
                <MenuRightMobile/>
              </div>
            }


        </div>
    </>
  );
}

