import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";

import { FaRegEnvelope, FaUnlock } from 'react-icons/fa';

import backgroundImg from '../../assets/hd-computer.png';
import hd2 from '../../assets/hd-2.png'
import logo from '../../assets/logo.png';

import './login.css';

export function Login() {
  
  const history = useHistory();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const { login, currentUser, resetPassword } = useAuth();
  const [ error, setError ] = useState('');
  const [ user, setUser ] = useState('');
  const [ password, setPassword ] = useState('');

  async function handleLoginSubmit(event) {

    event.preventDefault()
    try {
      setError('')

      setLoading(true);
      await login(user, password);
      
    } catch {
      setError(`complete os campos corretamente!`)
      setLoading(false);
    }
    
  }

  // useEffect(() => {
  //   if(currentUser){
  //     setLoading(false);
  //     history.push("/home");
  //   }
  //   return () => {  
  //   }
  // }, [currentUser, history])

  useEffect(() => {
    let t = setTimeout(() => {
      if(currentUser){
        setLoading(false);
        history.push("/home");
      }
    }, 2000);



    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [currentUser, history]);


  function handleGoToCadastro(){
    history.push("/cadastrocliente");
  }


  //redefinir Senha
  async function forgotPassword(email){
    //redefinir Senha

    await resetPassword(email).then(() => {
       alert('Foi enviado um e-mail com Link para redefinir sua senha! Entra lá, é Rapidinho.')
    })
    .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
    });
  }


  return(

    <div className="page-login">

      <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4 aside">
              <div className="row" id="login-title">
                <div className="col-sm-12">
                  <h1><strong>Catálogo de Produtos<br/>
                      MMV Distribuidora</strong></h1>
                    <p>Conheça aqui todos os produtos que oferecemos a você!
                      De modo rápido e descomplicado você consegue ver qual o
                      produto que deseja, facilitando assim sua compra.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <img src={backgroundImg} id="backgroundImg" alt="imagemBkg" className="img-responsive"/>
                </div>
              </div>

          </div>

            <div className="col-sm-8 main">

            {loading ? 
                <div className="col-sm-12">
                  <p style={{textAlign: 'center'}}>Aguarde...</p>
                </div>                
                  :
                <>

                <img id="hd2" src={hd2} alt="hd2" />
                <div className="row">
                  <div className="col-sm-12" id="logo">
                    <img src={logo} id="logotipo" alt="Logo MMV" className="img-responsive"/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12" id="botao">

                  <button 
                    className="btnViewCatalog"
                    type="button" 
                    onClick={handleGoToCadastro}>
                      CRIAR CONTA
                  </button> 
                    
                  </div>                   
                </div>  
                <div className="row">
                  <div className="col-sm-12">                      
                  <div className="linehome"/>

                  </div>
                </div>
                <div className="row">

                  <div className="col-sm-12" id="formulariologin">  

                    <form onSubmit={handleLoginSubmit}>                          
                      <p>Se já for cadastrado, entre com seus dados</p>
                      {error?<p className="erroLogin">{error}</p> :''}                        

                      <div id="input">
                          <div className="iconlogin"><FaRegEnvelope/></div>
                          <input id="homeinputmail"
                            type="email"
                            placeholder="digite seu email"
                            onChange={event => setUser(event.target.value)}
                            value={user}
                            className="clearable"
                          />
                      </div>                          
                      
                      <div id="input">
                        <div className="iconlogin"><FaUnlock/></div>
                        <input id="homeinputpass"
                          type="password"
                          placeholder="digite sua senha"
                          onChange={event => setPassword(event.target.value)}
                          value={password}
                        />
                      </div> 
                      
                      <div id="input">
                        <button 
                          disabled={loading} 
                          className="btnViewCatalog"
                          type="submit" 
                          onClick={()=>{}}>
                            ENTRAR
                        </button> 
                      </div> 
                        
                    </form>    
                  </div>
                  
                

                </div> 

                </>}

                <>

                {error !== '' && 
                <div className="row">
                  <div className="col-sm-12" style={{textAlign: 'center', cursor: 'pointer'}}>
                        <p className="forgotpassword" 
                        onClick={() => {forgotPassword(user)}}>
                          Esqueceu a senha? Clique aqui.
                        </p>
                      </div>
                </div>
                } 
                
                </>

            </div>
            
          </div>
      </div>

    </div>

   
  )

}