import React from 'react';

import { useProdutos } from '../../../contexts/ProdutosContext'; 

export function ProdutosCount() {

  const { produtosData } = useProdutos(); 

  return(
    <p style={{textAlign: 'center'}}><strong>{produtosData?.length}</strong><br/> Produtos Ativos</p>
  )

}