import React from 'react';
import { useCount } from '../../../contexts/CountContext'

export function PedidosCount() {
  const { pedidosCount } = useCount();

  return(
    <p style={{textAlign: 'center'}}><strong>{pedidosCount}</strong> <br/>Pedidos de<br/><strong>Orçamentos</strong></p>
  )

}