import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// import { useCategorias } from '../../contexts/CategoriasContext'; 
import { useUsuarios } from '../../contexts/UsuarioContext';
import { ShoppingListContext } from '../../contexts/ShoppingListContext';

// eslint-disable-next-line
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
// eslint-disable-next-line
import { FaHome, FaBars, FaTasks, FaBarcode, FaCartArrowDown, FaReceipt, FaSignOutAlt } from 'react-icons/fa';

import './style.scss'

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

export function MenuLeftCliente() {
  const history = useHistory();

  const [open, setOpen] = useState(true);
  // const { categoriasData } = useCategorias(); 
  const { usuario, handleLogoutSubmit } = useUsuarios();

  const { shoppingList } = useContext(ShoppingListContext);  

  function handleOpen(){
    setOpen(!open);
    setOpen(!open)
  }

  function handleGoToHome(){
    history.push('/home');
  }
  function handleGoToPedidos(){
    history.push('/pedidoscliente');
  }
  function handleGoToPedidosVendedor(){
    history.push('/pedidosvendedor');
  }

  // function handleGoToCategorias(){
  //   history.push('/categorias');
  // }
  // function handleGoToCategory(routeName){
  //   history.push({
  //     pathname: '/produtos',
  //     state: { nomedacategoria:`${routeName}`}
  //   });
  // }
  
  function handleGoToProdutos(){
    history.push({
      pathname: '/produtos',
      state: { nomedacategoria: null }
    });
  }

  function handleGoToCart(){
    history.push("/carrinho");
  }
 
  const toolTipAbrir = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Abrir
    </Tooltip>
  );
  const toolTipHome = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Home
    </Tooltip>
  );
  const toolTipPedidos = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Pedidos
    </Tooltip>
  );
  const toolTipProdutos = (props) => (
    <Tooltip id="button-tooltip" {...props}>
       Produtos
    </Tooltip>
  );
  // const toolTipCategorias = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Categorias
  //   </Tooltip>
  // );
  const toolTipSair = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Sair
    </Tooltip>
  );


  return(

      <ProSidebar width={250} collapsed={open}>
        <Menu iconShape="round">

          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipAbrir}>        
            <MenuItem icon={<FaBars />} onClick={handleOpen}></MenuItem>
          </OverlayTrigger>

          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipHome}>
            <MenuItem icon={<FaHome />} onClick={handleGoToHome}>Home</MenuItem>
          </OverlayTrigger>

          {usuario?.permissao === "vendedor" && usuario?.analise === true &&
          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipPedidos}>
            <MenuItem icon={<FaReceipt />} onClick={handleGoToPedidosVendedor}>Pedidos</MenuItem>
          </OverlayTrigger>
          }

          {usuario?.permissao === "cliente" && usuario?.analise === true &&
          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipPedidos}>
            <MenuItem icon={<FaReceipt />} onClick={handleGoToPedidos}>Pedidos</MenuItem>
          </OverlayTrigger>
          }

          {usuario?.permissao === "cliente" && usuario?.analise === true && shoppingList.length > 0 &&
          <MenuItem icon={<FaCartArrowDown />} alt="Carrinho" onClick={handleGoToCart} id="btncarrinhomobile" style={{color:'#92d24e'}}>Carrinho</MenuItem>
          }
          
          {usuario?.analise === true &&
          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipProdutos}>    
            <MenuItem icon={<FaBarcode />} alt="Produtos" onClick={handleGoToProdutos}>Produtos</MenuItem>
          </OverlayTrigger>
          }
        
          {/* {usuario?.analise === true &&
          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipCategorias}>
            <MenuItem icon={<FaBarcode />} onClick={handleGoToCategorias}>Categorias</MenuItem>
          </OverlayTrigger>
          } */}
        
          {/* {usuario?.analise === true &&
            <SubMenu title="Categorias" icon={<FaTasks />}>
              {categoriasData?.map(categorias => {
                return <MenuItem icon={<FaAngleRight />} key={categorias.CD_GRUPO}  
                onClick={() => handleGoToCategory([categorias.CD_GRUPO])}>
                {categorias.DESCRICAO}            
                </MenuItem>
              })}
            </SubMenu>        
          }*/}

          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipSair}>
            <MenuItem icon={<FaSignOutAlt/>} onClick={handleLogoutSubmit}>Sair</MenuItem>
          </OverlayTrigger>

        </Menu>
        
      </ProSidebar>

  )

}

// https://reactjsexample.com/customizable-and-responsive-react-sidebar-library-with-dropdown-menus/
// https://react-bootstrap.github.io/components/overlays/