import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
  // eslint-disable-next-line
import { database } from '../../services/firebase';
import api from '../../services/api';

import { ShoppingListContext } from '../../contexts/ShoppingListContext';
import { useUsuarios } from '../../contexts/UsuarioContext';
import { useCount } from '../../contexts/CountContext';
import { useVendedores } from '../../contexts/VendedoresListContext';

import Select from 'react-select';

import Item from './Item';

import { FaClipboardList } from 'react-icons/fa';
import './index.scss';
import './bag.css'

export function Bag() {

  const history = useHistory();
  const { shoppingList, productsCount, setProductsCount, setShoppingList } = useContext(ShoppingListContext);  

  const { usuario } = useUsuarios();
  const { vendedoresList } = useVendedores();
  // eslint-disable-next-line
  const [selecionados, setSelecionados] = useState([]);
  // eslint-disable-next-line
  const [total, setTotal] = useState(0);
  const [totalbanco, setTotalBanco] = useState(0)
    // eslint-disable-next-line
  const {pedidosCount, totalOrcamento} = useCount();

  const [selectedOption, setSelectedOption] = useState('MMV');

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      //borderBottom: '0.1px dashed #f8f8f8',
      color: state.isSelected ? '#9d9d9d' : '#9d9d9d',
      padding: 10,
      fontFamily: 'Poppins',
      backgroundColor:'#fff',
      fontSize:14,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: 'flex',
      backgroundColor:'#fff',
      justifyContent:'space-between',
      alignItems:'center',
      borderRadius:5,
      padding: 2,
      marginTop:1,
      fontFamily: 'Poppins',
      fontSize:14,
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.1 : 1;
      const transition = 'opacity 5000ms';
      return { ...provided, opacity, transition };
    }
  }

  useEffect(() => {
    let computed_total = 0;
    shoppingList.map(item => (
      computed_total += parseFloat(item.preco_venda * item.quantity)
    ));    
      setTotal((computed_total.toFixed(2)));
      setTotalBanco(parseFloat(computed_total.toFixed(2)));

      localStorage.setItem('prod_count', JSON.stringify(productsCount));
      localStorage.setItem('shopping_list', JSON.stringify(shoppingList));

      setSelecionados(shoppingList);    

  }, [shoppingList, productsCount]);

    // eslint-disable-next-line
  const totalformatado = totalbanco.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(",", ".");

  const optionsHora = {
    timeZone: 'America/Sao_Paulo',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  const timestamp = new Intl.DateTimeFormat('pt-BR').format(new Date());
  const hora = new Intl.DateTimeFormat([], optionsHora);
  const horaagora = hora.format(new Date())




  // eslint-disable-next-line
  async function criarPedidoBancoSql() {

    await api.post(`/criarpedido/`, { 
        id: pedidosCount + 1,
        numerodopedido: pedidosCount + 1,
        status:"Pendente",
        cnpj:usuario.cnpj,
        cliente:usuario,
        data:timestamp,
        hora: horaagora,
        vendedor:`${selectedOption?.label === undefined ? "MMV" : selectedOption?.label}`, 
        idvendedor:`${selectedOption?.value === undefined ? "MMV" : selectedOption?.value}`,         
        total:totalbanco,
        selecionados:selecionados,
    })
    console.log("rolou o cadastramento...")
    console.log(selecionados)
  }


  async function criarPedidoFirebase() {

    const novoPedido = database.ref(`pedidos/${pedidosCount + 1}`);

      const pedido = {
        data:timestamp,
        hora: horaagora,
        numerodopedido: pedidosCount + 1,
        status:"Pendente",
        cliente:usuario,
        itens: selecionados,   
        vendedor:`${selectedOption?.label === undefined ? "MMV" : selectedOption?.label}`, 
        idvendedor:`${selectedOption?.value === undefined ? "MMV" : selectedOption?.value}`,         
        total:totalbanco,
      }

    await novoPedido.set(pedido);
    await database.ref(`numerodopedido/`).set(pedidosCount + 1); 
    await database.ref(`totalorcamento/`).set(totalOrcamento + totalbanco);

  }

  async function enviaremail() {
    await api.get(`/send-email-novo-orcamento?email=${usuario.email}&name=${usuario.nome}&numerodopedido=${pedidosCount + 1}`);
  }

  const handleCheckOut = async () => {

    if(selectedOption === 'MMV'){
      alert('Por favor, selecione seu representante.')
      return;
    }

    criarPedidoFirebase();
    // const novoPedido = database.ref(`pedidos/${pedidosCount + 1}`);

    //   const pedido = {
    //     data:timestamp,
    //     hora: horaagora,
    //     numerodopedido: pedidosCount + 1,
    //     status:"Pendente",
    //     cliente:usuario,
    //     itens: selecionados,   
    //     vendedor:`${selectedOption?.label === undefined ? "MMV" : selectedOption?.label}`, 
    //     idvendedor:`${selectedOption?.value === undefined ? "MMV" : selectedOption?.value}`,         
    //     total:totalbanco,
    //   }

    // await novoPedido.set(pedido);
    // await database.ref(`numerodopedido/`).set(pedidosCount + 1); 
    // await database.ref(`totalorcamento/`).set(totalOrcamento + totalbanco); 

    enviaremail();

    //criarPedidoBancoSql();

    history.push({
      pathname: '/obrigado',
      state: { pedidoconfirmado: `${pedidosCount + 1}` }
    });

  }
  
  return (
      
    <div className="wrapper">

        {(usuario?.permissao === "superUser" || usuario?.permissao === "cliente" || usuario?.permissao === "vendedor") && (shoppingList.length > 0 ) &&
          <div className="vendedor">
            <p><strong>{selectedOption.label === undefined ? 'Selecione um Vendedor abaixo:' : `Vendedor(a): ${selectedOption.label}`} </strong></p>
                <Select 
                  className="selectShadow"
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={vendedoresList}
                  isSearchable={false}
                  styles={customStyles}                
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,                
                    colors: {
                      ...theme.colors,
                      neutral0: '#f8f8f8',
                      primary: '#f8f8f8',
                      primary50: '#f8f8f8',
                    },
                  })}
                />            
          </div>
        }

        <div className="items__container">
          {shoppingList.length > 0 ? (
            shoppingList.map((item, index) => {
              return (
                <Item
                  key={index}
                  item={item}
                  setProductsCount={setProductsCount}
                  productsCount={productsCount}
                  shoppingList={shoppingList}
                  setShoppingList={setShoppingList}
                />
              );
            })
          ) : (
            <div className="bag__empty"></div>
          )}
        </div>

        <div className="bag__footer">
            {(usuario?.permissao === "superUser" || usuario?.permissao === "vendedor") &&
            <div className="bag__total">            
              Total: <strong style={{color:'#afec6a'}}>{totalformatado}</strong>            
            </div>}

            {((usuario?.permissao === "cliente" || usuario?.permissao === "vendedor") && shoppingList.length > 0 )  &&
              <div className="checkout__btn" onClick={handleCheckOut}>
                <FaClipboardList style={{fontSize:25, marginBottom:5}}/> PEDIR ORÇAMENTO
              </div>
            }
        </div>

    </div>

  );

  
}
