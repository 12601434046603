import React, {useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { database } from '../../services/firebase';
import { useUsuarios } from '../../contexts/UsuarioContext';
// import { useCategorias } from '../../contexts/CategoriasContext';   FaAngleRight FaUserFriends
import { ShoppingListContext } from '../../contexts/ShoppingListContext';

// eslint-disable-next-line
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaBars, FaHome, FaRegIdBadge, FaCartArrowDown, FaBarcode, FaUserClock, FaUserPlus, FaReceipt, FaUsers, FaSignOutAlt, FaUserTie } from 'react-icons/fa';
import './style.scss'

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

export function MenuLeft() {
  const history = useHistory();

  const [open, setOpen] = useState(true);
  const [ clientesList, setClientesList ] = useState([]);
  // const { categoriasData } = useCategorias(); 

  const { handleLogoutSubmit } = useUsuarios();

  const { shoppingList } = useContext(ShoppingListContext); 

  function handleOpen(){
    setOpen(!open);
    setOpen(!open)
  }
  function handleGoToHome(){
    history.push("/home");
  }
  function handleGoToPedidos(routeName) {
    history.push({
      pathname: '/pedidos',
      state: { statusdopedido: routeName }
    });
  }
  function handleGoToCadastro(){
    history.push("/cadastro");
  }
  function handleGoToCadastroVendedores(){
    history.push("/cadastrovendedores");
  }
  function handleGoToAnalise(){
    history.push("/analise");
  }
  function handleGoToAnaliseVendedores(){
    history.push("/analisevendedores");
  }
  function handleGoToVendedores(){
    history.push("/vendedores");
  }
  function handleGoToListaClientes(){
    history.push("/clientes");
  }
  // function handleGoToCategorias(){
  //   history.push("/categorias");
  // }
  function handleGoToProdutos(){
    history.push({
      pathname: '/produtos',
      state: { nomedacategoria: null }
    });
  }

  // function handleGoToCategory(routeName) {
  //   history.push({
  //       pathname: '/produtos',
  //       state: { nomedacategoria: `${routeName}` }
  //     });
  // }

  function handleGoToCart(){
    history.push("/carrinho");
  }
 
  useEffect(() => {  
    const clientesRef = database.ref(`/usuarios/`);
      clientesRef.on('value', (snapshot) => {      
        const clientes = snapshot.val();
        const clientesList = [];
        for (let id in clientes) {
          clientesList.push ({ id, ...clientes[id] });
        }
        setClientesList(clientesList);
      });
    return () => {
      clientesRef.off('value');
    }
  }, []);

  const filtrovendedor = vendedor => vendedor.permissao === "vendedor";
  const vendedores = clientesList.filter(filtrovendedor);

  const filtrocliente = cliente => cliente.permissao === "cliente";
  const clientes = clientesList.filter(filtrocliente);

  const listaanalise = cliente => cliente.analise === false;
  const analisedeclientes = clientes.filter(listaanalise);
  const vendedoresanalise = vendedores.filter(listaanalise);

  const toolTipAbrir = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Abrir
    </Tooltip>
  );
  const toolTipHome = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Home
    </Tooltip>
  );
  const toolTipPedidos = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Pedidos
    </Tooltip>
  );
  const toolTipCadastroVendedores = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Cadastro Vendedores
    </Tooltip>
  );
  const toolTipAnaliseVendedores = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Análise Vendedores
    </Tooltip>
  );
  const toolTipVendedores = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Vendedores
    </Tooltip>
  );
  const toolTipCadastroCliente = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Cadastro Cliente
    </Tooltip>
  );
  const toolTipAnaliseCliente = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Análise Cliente
    </Tooltip>
  );
  const toolTipClientes = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Clientes
    </Tooltip>
  );
  const toolTipProdutos = (props) => (
    <Tooltip id="button-tooltip" {...props}>
       Produtos
    </Tooltip>
  );
  // const toolTipCategorias = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Categorias
  //   </Tooltip>
  // );
  const toolTipSair = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Sair
    </Tooltip>
  );

  return(

      <ProSidebar width={250} collapsed={open}>
        
        <Menu iconShape="round">
          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipAbrir}>
            <MenuItem icon={<FaBars />} onClick={handleOpen}></MenuItem>
          </OverlayTrigger>

          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipHome}>
            <MenuItem icon={<FaHome />} onClick={handleGoToHome}>Home</MenuItem>
          </OverlayTrigger>
          
          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipPedidos}>
            <MenuItem icon={<FaReceipt />} onClick={()=>{handleGoToPedidos('Pendente')}}>Pedidos</MenuItem>
          </OverlayTrigger>

          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipCadastroVendedores}>
            <MenuItem icon={<FaUserPlus />} onClick={handleGoToCadastroVendedores}>Cadastro Vendedores</MenuItem>
          </OverlayTrigger>

          {vendedoresanalise?.length > 0 &&
          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipAnaliseVendedores}>
            <MenuItem icon={<FaRegIdBadge />} onClick={handleGoToAnaliseVendedores}>Análise Vendedores</MenuItem>
          </OverlayTrigger>
          }

          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipVendedores}>
            <MenuItem icon={<FaUserTie/>} onClick={handleGoToVendedores}>Vendedores</MenuItem>
          </OverlayTrigger>
          

          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipCadastroCliente}>
            <MenuItem icon={<FaUserPlus />} onClick={handleGoToCadastro}>Cadastro Cliente</MenuItem>  
          </OverlayTrigger>

          {analisedeclientes?.length > 0 &&
          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipAnaliseCliente}>   
            <MenuItem style={{color:'#f90'}}icon={<FaUserClock />} onClick={handleGoToAnalise}>Análise ({analisedeclientes?.length})</MenuItem>
          </OverlayTrigger>
          }

          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipClientes}>    
            <MenuItem icon={<FaUsers/>} onClick={handleGoToListaClientes}>Clientes</MenuItem>
          </OverlayTrigger>

          {shoppingList.length > 0 &&
          <MenuItem icon={<FaCartArrowDown />} alt="Carrinho" id="btncarrinhomobile" style={{color:'#92d24e'}} onClick={handleGoToCart}>Carrinho</MenuItem>
          }

          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipProdutos}>    
            <MenuItem icon={<FaBarcode />} alt="Produtos" onClick={handleGoToProdutos}>Produtos</MenuItem>
          </OverlayTrigger>
        
            {/* <SubMenu title="Categorias" icon={<FaTasks />}>
              {categoriasData?.map(categorias => {
                return <MenuItem icon={<FaAngleRight />} key={categorias.CD_GRUPO}  
                onClick={() => handleGoToCategory([categorias.CD_GRUPO])}>
                {categorias.DESCRICAO}            
                </MenuItem>
              })}
            </SubMenu>  */}

          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={toolTipSair}>    
            <MenuItem icon={<FaSignOutAlt/>} onClick={handleLogoutSubmit}>Sair</MenuItem>
          </OverlayTrigger>

        </Menu>        
      </ProSidebar>

  )

}

// https://reactjsexample.com/customizable-and-responsive-react-sidebar-library-with-dropdown-menus/
// https://react-bootstrap.github.io/components/overlays/