import React, { useContext, useState, useEffect } from "react"
import { database } from '../services/firebase';

const VendedoresListContext = React.createContext()

export function useVendedores() {
  return useContext(VendedoresListContext);
}

export function VendedoresProvider({ children }) {

  const [vendedoresList, setVendedoresList] = useState([]);

  useEffect(() => {  
    const vendedoresRef = database.ref(`/vendedores/`);
      vendedoresRef.on('value', (snapshot) => {      
        const vendedores = snapshot.val();
        const vendedoresList = [];
        for (let id in vendedores) {
          vendedoresList.push ({ id, ...vendedores[id] });
        }
        setVendedoresList(vendedoresList);
      });
      return () => {vendedoresRef.off('value');}
    }, []);

    vendedoresList.sort(function (a, b) {
      return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
    });


    const value = {
      vendedoresList
    }

  return (
    <VendedoresListContext.Provider value={value}>
      {children}
    </VendedoresListContext.Provider>
  )

}