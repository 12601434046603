import React from 'react';
import { usePedidos } from '../../../contexts/PedidosContext';

export function AprovadosCount() {
  const { aprovados, somaaprovados } = usePedidos();

  function convertetobrl(x) {
    return Number.parseFloat(x).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(",", ".");
  }

    return(

      <p style={{textAlign: 'center'}}>
        <strong>{aprovados}</strong> <br/>Pedidos Aprovados<br/> <strong>{convertetobrl(somaaprovados)}</strong>
      </p>

    )
}