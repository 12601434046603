import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import api from '../../services/api';

import Card from './Card';
import './produtoscomponente.css';

export function ProdutosSQLComponent() {

  const location = useLocation();
  // eslint-disable-next-line 
  const [loading, setLoading]= useState(true);
  const [nomedacategoria, setNomedacategoria] = useState(null);

  const [buscarcodigo, setBuscarCodigo] = useState('');
  const [buscarnome, setBuscarNome] = useState('');
  // eslint-disable-next-line 
  const [grupo, setGrupo] = useState('');

  const [produtosData, setProdutosData] = useState([]);
  const [bkpData, setBkpData] = useState([]);

  const [visible, setVisible] = useState(30);

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 30);
  //https://www.youtube.com/watch?v=Ka3OQpwqxXA
  //https://www.npmjs.com/package/react-paginate
  //https://github.com/AdeleD/react-paginate
  }

  useEffect(() => {
    let isSubscribed = true;
      if(nomedacategoria !== null){
        setBuscarNome(nomedacategoria);
      }

    async function getProdutosSql() {
      const response = await api.get(`/produtos?CD_PRODUTO=${buscarcodigo}&DESCRICAO=${buscarnome}&CD_GRUPO=${grupo}`);
      if (isSubscribed) {
        setProdutosData(response.data);
        //setProdutosData([]);
        setLoading(false);
        setNomedacategoria(null);
      }        
    };

    getProdutosSql();
    return () => isSubscribed = false
    // eslint-disable-next-line 
  }, [buscarnome, buscarcodigo, nomedacategoria]);


  useEffect(() => {
    setNomedacategoria(location?.state?.nomedacategoria);
  }, [location]);


  useEffect(() => {
    let isSubscribed = true;
    async function getBkpSql() {
      const response = await api.get(`/puxabkp`);
      if (isSubscribed) {
        setBkpData(response.data); 
        setLoading(false);       
      }        
    };

    getBkpSql();
    return () => isSubscribed = false
    // eslint-disable-next-line 
  }, []);


  return(
  
  <>  
    <div className="container-fluid" id="filtrinhos">
      <div className="row">

        <div className="col-sm-4 col-md-4 col-sx-12 col-lg-4">
          <div className="inputfiltroprodutos">
            <input  id="inputfiltroprodutos"
              type="number" 
              placeholder="código" 
              disabled = {false}
              onChange={event => setBuscarCodigo(event.target.value)}
              value={buscarcodigo}
              />                          
          </div>
        </div>

        <div className="col-sm-8 col-md-8 col-sx-12 col-lg-8">
          <div className="inputfiltroprodutos">
            <input id="inputfiltroprodutos"
              type="text" 
              placeholder="nome do produto" 
              disabled = {false}
              onChange={event => setBuscarNome(event.target.value.toUpperCase())}
              value={buscarnome}                
              />                          
          </div>
        </div>

      </div>
    </div>
    <div className="product__wrapper">
    {loading ? <p className="text-loading" style={{textAlign: 'center', padding:100}}>Aguarde...</p> :
      <div className="card__container">
        {produtosData.length > 0 ? produtosData?.slice(0, visible).map((p, index) => {
          return <Card key={index} product={p}/>
        })        
        :
        bkpData?.slice(0, visible).map((p, index) => {
          return <Card key={index} product={p}/>
        })        
        }              
      </div>
    }
      <button className="btnLoadMore" onClick={showMoreItems}>Carregar Mais</button>
    </div>
  </>
  )
}