import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useUsuarios } from '../../contexts/UsuarioContext';
import { FaSignOutAlt } from 'react-icons/fa';

import logo from '../../assets/logo.png'
import './header.css'

export function Header() {

  const history = useHistory();
  const { usuario, handleLogoutSubmit, error, currentUser } = useUsuarios();
  const [ cumprimentos, setCumprimentos ] = useState('');

  useEffect(() => {
    if(!usuario || !currentUser){
      history.push('/')
    }
    return () => {  
    }
  }, [usuario, history, currentUser])

    useEffect(() => {
      let isSubscribed = true;
        if (isSubscribed) {
          const greetingMessage = () => {
            let h = new Date().toLocaleTimeString('pt-BR', {hour: 'numeric', hour12: false});
            if (h >= 0 && h <= 5) { 
              setCumprimentos('!') ;
            } else if (h >= 6 && h < 12) { 
              setCumprimentos(', Bom dia!') ;
            } else if (h >= 12 && h < 18) { 
              setCumprimentos(', Boa tarde!') ;
            } else if (h >= 18 && h <= 23) { 
              setCumprimentos(', Boa noite!') ;
            }
          }
          greetingMessage();      
        }
        return () => {
          isSubscribed = false;
        }
    }, []);

  function handleGoToHome(){
    history.push('/home')
  }

  return( 

    <div className="container-fluid" id="header">  
        <div className="row">
          <div className="col-lg-4 col-sm-8 col-sx-12" id="logommv">
            <img src={logo} alt="logo MMV" onClick={handleGoToHome} />

            <div id="title" style={{cursor: 'pointer'}} onClick={handleGoToHome}>
              <strong>Catálogo de Produtos<br/>MMV Distribuidora</strong>
            </div>
            
          </div>
          <div className="col-lg-4 col-sm-1" id="meio">
            {error? error && <p>erro...</p> : ''}
          </div>
          <div className="col-lg-4 col-sm-3 col-sx-12" id="logout">
          {usuario && <p style={{color:'#fff', paddingRight:20, fontWeight:'bold'}} id="hello"> Olá {usuario?.nome}{cumprimentos}</p>}
          {usuario && <p style={{paddingRight:20, fontWeight:'bold'}} id="sair"><FaSignOutAlt onClick={handleLogoutSubmit}/></p>}
          </div>            
        </div>
    </div>

  );
}
