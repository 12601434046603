import React from 'react';
import { useCount } from '../../../contexts/CountContext';

export function ClientesCount() {
  const { codigocliente } = useCount();

  return(
    <p style={{textAlign: 'center'}}><strong>{codigocliente}</strong><br/>Clientes</p>
  )

}