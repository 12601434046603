import React, { useState, useEffect, useMemo } from 'react';
// eslint-disable-next-line
import { Container, Table, Row, Col } from 'react-bootstrap';

import {database} from '../../services/firebase';
import { FaUserClock } from 'react-icons/fa';

import './clientesanalisados.css'

export function ClientesAnalisados() {

  const [clientesList, setClientesList] = useState([]);
  const [buscar, setBuscar] = useState('');
  const [search, setSearch] = useState('');
  const [buscarcontato, setBuscarContato] = useState('');
  const [buscarEmail, setBuscarEmail] = useState('');

  useEffect(() => {  
    const clientesRef = database.ref(`/usuarios/`);
      clientesRef.on('value', (snapshot) => {      
        const clientes = snapshot.val();
        const clientesList = [];
        for (let id in clientes) {
          clientesList.push ({ id, ...clientes[id] });
        }
        setClientesList(clientesList);
      });
    return () => {
      clientesRef.off('value');
    }
  }, []);

  const permissao = cliente => cliente.permissao === 'cliente';
  const permissaocliente = clientesList.filter(permissao);

  const listaanalise = cliente => cliente.analise === true;
  const analisedeclientes = permissaocliente.filter(listaanalise);

  // const reprovado = cliente => cliente.analise === false;
  // const clientesreprovados = permissaocliente.filter(reprovado);

  analisedeclientes.sort(function (a, b) {
    return (a.razaosocial > b.razaosocial) ? 1 : ((b.razaosocial > a.razaosocial) ? -1 : 0);
  });

  const maisfiltrados = useMemo(() => {  
    if (!buscar) return analisedeclientes;
   // eslint-disable-next-line
   return analisedeclientes.filter(cliente => cliente?.cod == buscar);   
  }, [buscar, analisedeclientes]);

  const filtradospornome = useMemo(() => {
      if (!search) return maisfiltrados;
      return maisfiltrados.filter((cliente) => {
        return (
            cliente.razaosocial.toUpperCase().includes(search.toUpperCase())
        );
      });
  }, [search, maisfiltrados]);

  const filtradosporcontato = useMemo(() => {
    if (!buscarcontato) return filtradospornome;
    return filtradospornome.filter((cliente) => {
      return (
          cliente.nome.toUpperCase().includes(buscarcontato.toUpperCase())
      );
    });
  }, [buscarcontato, filtradospornome]);

  const filtradosporemail = useMemo(() => {
    if (!buscarEmail) return filtradosporcontato;
    return filtradosporcontato.filter((cliente) => {
      return (
          cliente.email.toLowerCase().includes(buscarEmail.toLowerCase())
      );
    });
  }, [buscarEmail, filtradosporcontato]);

  async function handleActive(routeName) {
    const clienteRef = database.ref(`/usuarios/`).child(`${routeName}/`);
    await clienteRef.update({
      analise:false
    });
  }

  return(

    <>
    
    <div className="container-fluid" id="filtrinhos">

      <div className="row">
        <div className="col-sm-2 col-md-2 col-xs-12 col-lg-3">
          <div className="inputfiltroclientesap">
            <input id="inputfiltroclientesap"
              type="text" 
              placeholder="código" 
              disabled = {false}
              onChange={event => setBuscar(event.target.value)}
              value={buscar}
              />                          
          </div>
        </div>

        <div className="col-sm-3 col-md-3 col-xs-12 col-lg-3">
          <div className="inputfiltroclientesap">
            <input id="inputfiltroclientesap"
              type="text" 
              placeholder="razão social" 
              disabled = {false}
              
              onChange={event => setSearch(event.target.value.toUpperCase())}
              value={search}
              />                          
          </div>
        </div>

        <div className="col-sm-3 col-md-3 col-xs-12 col-lg-3">
          <div className="inputfiltroclientesap">

            <input id="inputfiltroclientesap"
              type="text" 
              placeholder="cliente" 
              disabled = {false}
              
              onChange={event => setBuscarContato(event.target.value.toUpperCase())}
              value={buscarcontato}
              />                          
          </div>
        </div>

        <div className="col-sm-3 col-md-3 col-xs-12 col-lg-3">
          <div className="inputfiltroclientesap">

            <input  id="inputfiltroclientesap"
              type="text" 
              placeholder="e-mail do cliente" 
              disabled = {false}
              
              onChange={event => setBuscarEmail(event.target.value.toLowerCase())}
              value={buscarEmail}
              />                          
          </div>
        </div>

      </div>
    </div>
    
    <Container fluid style={{marginTop:20}} id="containerclientes">

        {filtradosporemail.map((cliente, index) => (

          <div key={cliente?.id} id="cardcliente">

            <Row>
              <Col>
                <p>Código: {cliente?.cod} </p>        
              </Col>
              <Col>
                <p>Criado: {cliente?.createat}</p>        
              </Col>
            </Row>

            <Row>
              <Col>
                <p>CNPJ: {cliente?.cnpj}</p>        
              </Col>
            </Row>

            <Row className="colunarazao">
              <Col>
                <p>Razão: {cliente?.razaosocial}</p>        
              </Col>
            </Row>

            <Row>
              <Col>
                <p>Nome: {cliente?.nome}</p>       
              </Col>
            </Row>

            <Row>
              <Col>
              <p>{cliente?.email}</p>      
              </Col>
            </Row>

            <Row>
              <Col>
                <p>Celular: {cliente?.celular}</p>       
              </Col>
            </Row>

            <Row>
              <Col>
                <p>Cidade: {cliente?.endereco?.cidade}</p>       
              </Col>
            </Row>

            <Row>
              <Col>
                <p>Último Login: {cliente?.ultimologin}</p>       
              </Col>
            </Row>

            <Row className="actionbtn">
              <Col>
              <button type="button" className="btnReprovar" 
                onClick={()=>{handleActive(cliente.uid)}}><FaUserClock/><span> Reprovar</span>
              </button>
              </Col>
            </Row>         

          </div>
        ))}

    </Container>


    </>

  );

}
