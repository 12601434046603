import React, { useState, useEffect } from 'react';
import {database} from '../../services/firebase';
import { Container, Table, Row, Col } from 'react-bootstrap';

import './vendedores.css'

export function VendedoresComponente(){
  const [ vendedoresList, setVendedoresList ] = useState([]);

  useEffect(() => {  
    const usuariosRef = database.ref(`/usuarios/`);
    usuariosRef.on('value', (snapshot) => {      
        const vendedores = snapshot.val();

        const vendedoresList = [];
        for (let id in vendedores) {
          vendedoresList.push ({ id, ...vendedores[id] });
        }
        setVendedoresList(vendedoresList);
      });
    return () => {
      usuariosRef.off('value');
    }
  }, []);

  const permissao = vendedor => vendedor.permissao === 'vendedor';
  const vendedores = vendedoresList.filter(permissao);
  const analisdados = vendedor => vendedor.analise === true;
  const vendedoresanalisados = vendedores.filter(analisdados);

  vendedoresanalisados.sort(function (a, b) {
    return (a.cod > b.cod) ? 1 : ((b.cod > a.cod) ? -1 : 0);
  });



  async function handleActive(routeName) {
    const vendedorRef = database.ref(`/usuarios/`).child(`${routeName}/`);
    await vendedorRef.update({
      analise:false
    });
  }

  return(

    <Container fluid style={{marginTop:20}} id="containervendedor">      

{/* 
        <Table striped bordered hover responsive className="tabelavendedoressanalisados">
          {vendedoresanalisados.length > 0 &&
            <thead>
              <tr>              
                <th>Código</th>
                <th>Nome</th>
                <th>Email</th> 
                <th>Ultimo Login</th> 
                <th>Celular</th>             
                <th>Ações</th>
              </tr>
            </thead>
          }
          <tbody>   
              {vendedoresanalisados.map((vendedor, index) => (
              <tr key={vendedor?.id}>
                  <td>{vendedor?.cod}</td>
                  <td>{vendedor?.nome}</td>
                  <td>{vendedor?.email}</td>
                  <td>{vendedor?.ultimologin}</td>
                  <td>{vendedor?.celular}</td>
                  <td><button type="button" className="btnReprovar" onClick={()=>{handleActive(vendedor.uid)}}>REPROVAR</button></td>
              </tr>
            ))}                         
          </tbody>
        </Table>  */}

        {vendedoresanalisados.map((vendedor, index) => (

        <Row key={vendedor?.id} id="cardvendedor">
        
          <Row>
            <Col>
            <p>Código: {vendedor?.cod}</p>        
            </Col>
          </Row>

          <Row>
            <Col>
            <p>Nome: {vendedor?.nome}</p>       
            </Col>
          </Row>

          <Row>
            <Col>
            <p>{vendedor?.email}</p>      
            </Col>
          </Row>

          <Row>
            <Col>
            <p>Senha: {vendedor?.senha}</p>      
            </Col>
          </Row>


          <Row>
            <Col>
            <p>celular: {vendedor?.celular}</p>       
            </Col>
          </Row>

          <Row>
            <Col>
            <p>Último Login: {vendedor?.ultimologin}</p>       
            </Col>
          </Row>

          <Row>
            <Col>
            <p><button type="button" className="btnReprovar" onClick={()=>{handleActive(vendedor.uid)}}>REPROVAR</button> </p>      
            </Col>
          </Row>         

      </Row>
      ))}



    </Container>
  )
}