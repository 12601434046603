import React, { useContext, useState, useEffect } from "react"

import api from '../services/api';

const ProdutoContext = React.createContext()

export function useProdutos() {
  return useContext(ProdutoContext);
}

export function ProdutosProvider({ children }) {

  const [produtosData, setProdutosData] = useState([]);

  useEffect(() => {
    let isSubscribed = true
    async function getProdutosSql() {
      const response = await api.get('/produtoscount', { 
        body: JSON.stringify(produtosData)
      });
      if (isSubscribed) {
        setProdutosData(response.data);
      }        
    };
    getProdutosSql();
    return () => isSubscribed = false
    // eslint-disable-next-line 
  }, []);


  // https://www.edsonemiliano.com.br/blog/como-ordenar-uma-array-de-objetos-com-javascript-sort/

  const value = {
    produtosData
  }

  // console.log(produtosData)

  return (
    <ProdutoContext.Provider value={value}>
      {children}
    </ProdutoContext.Provider>
  )

}