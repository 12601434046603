import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Table, Row, Col } from 'react-bootstrap';

import {database} from '../../services/firebase';

import './vendedoresanalisecomponente.css'

export function VendedoresAnaliseComponente(){
  
  const history = useHistory();

  const [ vendedoresList, setVendedoresList ] = useState([]);

  useEffect(() => {  
    const usuariosRef = database.ref(`/usuarios/`);
    usuariosRef.on('value', (snapshot) => {      
        const vendedores = snapshot.val();

        const vendedoresList = [];
        for (let id in vendedores) {
          vendedoresList.push ({ id, ...vendedores[id] });
        }
        setVendedoresList(vendedoresList);
      });
    return () => {
      usuariosRef.off('value');
    }
  }, []);

  const permissao = vendedor => vendedor.permissao === 'vendedor';
  const vendedores = vendedoresList.filter(permissao);

  const analisdados = vendedor => vendedor.analise === false;
  const vendedoresanalisados = vendedores.filter(analisdados);

  async function handleActive(routeName) {
    const vendedorRef = database.ref(`/usuarios/`).child(`${routeName}/`);
    await vendedorRef.update({
      analise:true
    });
  }

  useEffect(() => {
    let t = setTimeout(() => {
      if(vendedoresanalisados.length === 0) {
        history.push('/vendedores')
      }
    }, 500);

    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [vendedoresanalisados]);


  return(

    <>

    <Container fluid style={{marginTop:20}} id="containervendedor">  

        {/* <Table striped bordered hover responsive className="tabelavendedoresanalise">
          {vendedoresanalisados.length > 0 &&
            <thead>
              <tr>              
                <th>Código</th>
                <th>Nome</th>
                <th>Email</th> 
                <th>Celular</th>             
                <th>Ações</th>
              </tr>
            </thead>
          }
            <tbody>   
               {vendedoresanalisados.map((vendedor, index) => (
                <tr key={vendedor?.id}>
                    <td>{vendedor?.cod}</td>
                    <td>{vendedor?.nome}</td>
                    <td>{vendedor?.email}</td>
                    <td>{vendedor?.celular}</td>
                    <td><button type="button" className="btnAprovar" onClick={()=>{handleActive(vendedor.uid)}}>APROVAR</button></td>
                </tr>
               ))}                         
            </tbody>
        </Table>  */}


        {vendedoresanalisados.map((vendedor, index) => (

        <Row key={vendedor?.id} id="cardvendedor">
                
            <Row>
              <Col>
              <p>Código: {vendedor?.cod}</p>        
              </Col>
            </Row>

            <Row>
              <Col>
              <p>Nome: {vendedor?.nome}</p>       
              </Col>
            </Row>

            <Row>
              <Col>
              <p>{vendedor?.email}</p>      
              </Col>
            </Row>

            <Row>
              <Col>
              <p>Senha: {vendedor?.senha}</p>      
              </Col>
            </Row>

            <Row>
              <Col>
              <p>celular: {vendedor?.celular}</p>       
              </Col>
            </Row>

            <Row>
              <Col>
              <p>Último Login: {vendedor?.ultimologin}</p>       
              </Col>
            </Row>

            <Row>
              <Col>
              <p><button type="button" className="btnAprovarAnaliseVendedor" onClick={()=>{handleActive(vendedor.uid)}}>APROVAR</button> </p>      
              </Col>
            </Row>         
        
        </Row>
        ))}


    </Container>

    </>
  )
}