import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Collapse, Dropdown } from 'react-bootstrap';
import "react-confirm-alert/src/react-confirm-alert.css";

import { useUsuarios } from '../../contexts/UsuarioContext';
import { usePedidos } from '../../contexts/PedidosContext'

import { FaRegUser, FaRegClock, FaRegCheckCircle, FaListUl, FaListOl } from 'react-icons/fa';
//import { BiMailSend, BiPrinter } from "react-icons/bi";

import './pedidosclientecomponente.css';

export function PedidosClienteComponent() {

  const history = useHistory();
  const { usuario } = useUsuarios();
  const { pedidosList } = usePedidos();

  const [buscar, setBuscar] = useState('');
  // eslint-disable-next-line
  const [tipoFiltro, setTipoFiltro] = useState('status');
  const [filtroPendente, setFiltroPendente] = useState("Pendente");
  const [mostrarpedido, setMostrarPedido] = useState(false);

  const listaporid = pedido => pedido?.cliente?.uid === usuario?.uid;
  const filtropeloid = pedidosList?.filter(listaporid)

  const listapendentes = pedido => pedido[tipoFiltro] === filtroPendente;
  const pedidosfiltrados = filtropeloid?.filter(listapendentes)

  const maisfiltrados = useMemo(() => {  
    if (!buscar) return pedidosfiltrados;

     // eslint-disable-next-line
   return pedidosfiltrados.filter(pedido => pedido?.numerodopedido == buscar);   
  }, [buscar, pedidosfiltrados]);

  async function handleFiltrarPendentes(){
    setFiltroPendente("Pendente");
  }
  async function handleFiltrarAprovados(){
    setFiltroPendente("Aprovado");
  }

  maisfiltrados.reverse();

  function handleMostrarPedido(id){
    setMostrarPedido(prevState => (
      {...prevState, [id]: !prevState[id]}
    ))
  }
  //https://stackoverflow.com/questions/62454545/how-to-collapse-only-one-div-when-using-react-and-map


  function handleGoToProdutos(routeName) {
    history.push({
      pathname: '/produtos',
      state: { nomedacategoria: routeName }
    });
  }

  return(
    <>

    <div className="row" id="filtrinhos">
            <div className="col-sm-2 col-md-3 col-xs-12 col-lg-2">
              <div className="inputfiltropedidos-ct">
                {/* <p>Nº Pedido:</p> */}
                <input 
                  id="inputfiltropedidos-ct"
                  type="text" 
                  placeholder="pedido" 
                  disabled = {false}
                  onChange={event => setBuscar(event.target.value)}
                  value={buscar}
                  autoComplete="off"
                  />                          
              </div>
            </div>

            <div className="col-sm-2 col-md-2 col-xs-12 col-lg-2">
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="dropdownfilters">
                      {filtroPendente === 'Pendente' ? 
                       <p><FaRegClock style={{color:'#f90'}}/> <span style={{color:'#f90'}}> Pendentes</span></p> : 
                       <p><FaRegCheckCircle style={{color:'#3c8b0f'}}/> <span style={{color:'#3c8b0f'}}> Aprovados</span></p>
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleFiltrarAprovados} style={{color:'#3c8b0f'}}><FaRegCheckCircle/> Aprovados</Dropdown.Item>
                      <Dropdown.Item onClick={handleFiltrarPendentes} style={{color:'#f90'}}><FaRegClock/> Pendentes</Dropdown.Item>                        
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>

    <div className="container-fluid" id="pedidosclientescontent">
      
      {maisfiltrados?.map((pedidos, idx) => (

        <div className="container-fluid" style={{border: '1px solid #999', marginBottom:20, borderRadius:5}} 
        pedidos={pedidos} key={pedidos?.numerodopedido}>  

          <div className="row cinza" style={{borderRadius:5}}>
              <div className="col-sm-8 col-lg-8 alinharpedidocliente">

                <Button className="btnCollapse" variant="secondary" id="showorder-ct"
                  onClick={() => handleMostrarPedido(pedidos?.numerodopedido)}
                  aria-controls="divpararenderizar"
                  aria-expanded={mostrarpedido}> 
                  <p><i><FaListUl/> </i><span> Pedido </span><strong style={{color:'#666'}}> {pedidos?.numerodopedido}</strong></p>  
                </Button>

                <Button className="btnCollapse" variant="secondary" id="valor-ct"  
                  onClick={() => handleMostrarPedido(pedidos?.numerodopedido)}>
                   <p>{pedidos?.data}</p> 
                </Button>

                <Button className="btnCollapse" variant="secondary" id="empresa-ct"
                  onClick={() => handleMostrarPedido(pedidos?.numerodopedido)}
                  aria-controls="divpararenderizar"
                  aria-expanded={mostrarpedido}> 
                   <p><i><FaRegUser style={{color:'#B08653'}}/></i> {pedidos?.cliente?.razaosocial} </p> 
                </Button>


              </div>
            
              <div className="col-sm-4 col-lg-4">                 
                <div className="alinhar">  

                  {pedidos?.status === "Pendente" &&
                    <Button className="btnCollapse" variant="secondary" id="reprove-ct"
                    onClick={() => {}}>
                      <p><i><FaRegClock/></i><span> Pendente</span></p>                  
                    </Button>
                  }
                  {pedidos?.status === "Aprovado" &&
                    <Button className="btnCollapse" variant="secondary" id="aprove-ct"  
                    onClick={() => {}}>
                      <p><i><FaRegCheckCircle/></i> Aprovado</p>                      
                    </Button>
                  }
                  <Button className="btnCollapse" variant="secondary" id="view-ct"
                    onClick={() => handleMostrarPedido(pedidos?.numerodopedido)}
                    aria-controls="divpararenderizar"
                    aria-expanded={mostrarpedido}> 
                   <p><i><FaListOl/></i> <span>Itens</span> <strong style={{color:'#666'}}> {pedidos?.itens?.length}</strong></p>
                  </Button>
              </div> 
            </div>
          </div>          



          <Collapse in={mostrarpedido[pedidos?.numerodopedido]}>
            <div id="divpararenderizar-ct" style={{paddingTop:20}}>   
                <div className="row">                      
                    <div className="col-sm-3">   
                      <p>Data: {pedidos?.data}<span style={{paddingLeft:20}}>Hora: {pedidos?.hora}</span></p> 
                      <p>Nome: {pedidos?.cliente?.razaosocial}</p>
                      <p>CNPJ: {pedidos?.cliente?.cnpj}</p>    
                      <p>Pedido: {pedidos?.numerodopedido}</p>    
                      <p>Status: {pedidos?.status}</p>                   
                    </div>
                    <div className="col-sm-3">
                      <p>Endereço: {pedidos?.cliente?.endereco?.logradouro}, {pedidos?.cliente?.endereco.numero} <span>{pedidos?.cliente?.endereco?.complemento}</span></p>   
                      <p>Bairro: {pedidos?.cliente?.endereco?.bairro}</p> 
                      <p>Cidade: {pedidos?.cliente?.endereco?.cidade}</p>
                      <p>Estado: {pedidos?.cliente?.endereco?.estado} </p>  
                      <p>CEP: {pedidos?.cliente?.endereco?.cep}</p>             
                    </div>   
                    <div className="col-sm-3">
                      <p>Contato: {pedidos?.cliente?.nome}</p>
                      <p>E-mail: {pedidos?.cliente?.email}</p>  
                      <p>Celular: {pedidos?.cliente?.celular}</p>                           
                      <p>Tel: {pedidos?.cliente?.telefone}</p>                
                    </div>
                    <div className="col-sm-3">
                      <p>Vendedor: {pedidos?.vendedor}</p> 
                      {/* <p>E-mail: {pedidos?.cliente?.emailvendedor}</p>     
                      <p>Tel: {pedidos?.cliente?.telefonevendedor}</p>                                                        */}
                    </div>
                </div>
                <div className="row cinzaescuro" >
                  <div className="col-sm-1">
                    <p>Código</p>
                  </div>  
                  <div className="col-sm-10">
                    <p>Nome</p>
                  </div>
                  {/* <div className="col-sm-1">
                    <p>Un</p>
                  </div>
                  <div className="col-sm-2">
                    <p>Preço Unid.</p>
                  </div>     */}
                  <div className="col-sm-1">
                    <p>Qtde.</p>
                  </div>   
                  {/* <div className="col-sm-1">
                    <p>Estoque</p>
                  </div> 
                  <div className="col-sm-1">
                    <p>Total</p>
                  </div> */}
                </div> 

            {pedidos?.itens?.map((itens, idx) => (

              <div className="row listaitens" style={{ borderBottomLeftRadius:10, borderBottomRightRadius:10 }}
              itens={itens} key={itens.CD_PRODUTO}>
                <div className="col-sm-1">
                  <p>{itens?.CD_PRODUTO}</p>
                </div> 
                <div className="col-sm-10">
                  <p>{itens?.DESCRICAO}</p>
                </div>   
                {/* <div className="col-sm-1">
                  <p>{itens?.package}</p>
                </div>
                <div className="col-sm-2">
                  <p>R$ {financial(itens?.preco_venda)}</p>
                </div> */}
                <div className="col-sm-1">
                  <p>{itens?.quantity}</p>
                </div> 
                {/* <div className="col-sm-1">
                  <p>{stoqueround(itens?.estoque_saldo)}</p> 
                </div> 
                <div className="col-sm-1">
                  <p>R$ {(itens?.quantity * itens?.preco_venda).toFixed(2)}</p>
                </div> */}
              </div>                       
            ))} 
            
            <div className="row cinza">
              <div className="col-sm-12">
                <p style={{textAlign: 'center', padding:10}}>MMV Comércio de Bike e Peças Eireli | CNPJ: 35.932.506/0001-13 </p>                    
              </div>
            </div> 

          </div> 

        </Collapse>

      </div>              
      ))}

      {maisfiltrados.length < 1 && 
      <p style={{textAlign: 'center', padding:10}}>
        Não temos pedidos para lhe mostrar. 
        <span style={{cursor:'pointer', color:'#B08653', fontWeight:'bold'}} onClick={()=>{handleGoToProdutos(null)}}> Clique aqui </span>
        e faça o seu primeiro pedido.
      </p>}

    </div>

  </>

  )
}
