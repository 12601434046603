import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { MenuLeftCliente } from '../../components/MenuLeftCliente';
import { PedidosClienteComponent } from '../../components/PedidosCliente';
import './pedidoscliente.css'

export function PedidosCliente() {
  const history = useHistory();

  const { usuario } = useUsuarios();

  useEffect(() => {
    if(!usuario){
      history.push('/')
    }
    return () => {  
    }
  }, [usuario, history])


  return(
    <>
    <Header/>
        <div id="page-pedidos-cliente">
          <div id="lateral">
            {usuario?.permissao === 'cliente' && usuario.analise === true && <MenuLeftCliente/>}
          </div>
          <div id="principal-pedidos-cliente">
            {/* <h5>Pedidos da MMV</h5> */}
            {usuario?.permissao === 'cliente' && usuario.analise === true && <PedidosClienteComponent/> }            
          </div>
        </div>
    </>
  );
}

