import React from "react";
import { FaReceipt} from 'react-icons/fa';

import './componentetoprint.css';

//https://codesandbox.io/s/cool-shape-39kxvx?file=/src/ComponentToPrint.js

export const ComponentToPrint = React.forwardRef((props, ref,) => {

  function convertetobrl(x) {
    return Number.parseFloat(x).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(",", ".");
  }

  return (

        <div className="print-preview" ref={ref}>

          <title>Pedido {props.numerodopedido} | MMV Distribudora</title> 

          <table> 
              <thead className="header-cliente-print-top">
                <tr>
                  <td style={{textAlign: 'center', width:'21cm', fontSize:'12px'}}>MMV Comércio de Bike e Peças Eireli | CNPJ: 35.932.506/0001-13</td>
                </tr>
              </thead>
          </table>

          <table> 
              <thead>
                <tr>
                  <td style={{textAlign: 'center', width:'21cm', fontSize:'12px', color:"#fff"}}>-----</td>
                </tr>
              </thead>
          </table>

          <table> 
              <thead className="header-cliente-print">
                <tr>
                  <th className="pedido-cliente-print"><FaReceipt/> Pedido nº {props.numerodopedido}</th>
                  <th className="data-cliente-print">{props?.data}</th>
                  <th className="cliente-cliente-print">{props?.cliente?.razaosocial}</th>
                  <th className="cnpj-cliente-print">CNPJ: {props?.cliente?.cnpj}</th>
                  <th className="status-cliente-print">Status: {props?.status}</th>
                </tr>
              </thead>              
          </table>

          <table>
            <thead>
              <tr>
                <td className="fonte-padrao-print pd-first">Data e Hora: {props?.data} - {props?.hora}</td>
                <td className="fonte-padrao-print pd-sec">Endereço: {props?.cliente?.endereco?.logradouro}</td>
                <td className="fonte-padrao-print pd-three">Contato: {props?.cliente?.nome}</td>

              </tr>
              <tr>
                <td className="fonte-padrao-print pd-first">Empresa: {props?.cliente?.razaosocial}</td>
                <td className="fonte-padrao-print pd-sec">Bairro: {props?.cliente?.endereco?.bairro}</td>
                <td className="fonte-padrao-print pd-three">Email: {props?.cliente?.email}</td>
              </tr>
              <tr>
              <td className="fonte-padrao-print pd-first">CNPJ: {props?.cliente?.cnpj}</td>
              <td className="fonte-padrao-print pd-sec">Cidade: {props?.cliente?.endereco?.cidade}</td>
              <td className="fonte-padrao-print pd-three">Celular: {props?.cliente?.celular}</td>
              </tr>
              <tr>
                <td className="fonte-padrao-print pd-first">Pedido: {props.numerodopedido}</td>
                <td className="fonte-padrao-print pd-sec">Estado: {props?.cliente?.endereco?.estado}</td>
                <td className="fonte-padrao-print pd-three">Telefone: {props?.cliente?.telefone}</td>
              </tr>
              <tr>
                <td className="fonte-padrao-print pd-first">Itens: {props.listaitens?.length}</td>
                <td className="fonte-padrao-print pd-sec">Valor do Pedido: {props.total}</td>
                <td className="fonte-padrao-print pd-three">Vendedor: JULIO</td>
              </tr>
            </thead>
          </table>

          <table> 
              <thead className="header-cliente-print-btt">
                <tr>
                  <td style={{textAlign: 'center', width:'21cm'}}></td>
                </tr>
              </thead>
          </table>

          <table> 
              <thead>
                <tr>
                  <td style={{textAlign: 'center', width:'21cm', fontSize:'12px', color:"#fff"}}>-----</td>
                </tr>
              </thead>
          </table>
            
          <table>              
              <tbody> 
              <div className="itens-pedido-print">
                <tr className="header-line-print">
                  <th className="codigo-header-print">CODIGO</th>
                  <th className="descricao-header-print">NOME</th>
                  <th className="valorunidade-header-print">PREÇO/UNID.</th>
                  <th className="quantidade-header-print">QTD.</th>
                  <th className="total-header-print">TOTAL</th>
                </tr>
                  {props.listaitens?.map((itens, idx) => (
                    <tr itens={itens} key={idx.CD_PRODUTO} className="lista-de-itens-print">
                      <th className="codigo-header-print"> {itens.CD_PRODUTO}</th>
                      <th className="descricao-header-print"> {itens.DESCRICAO}</th>                    
                      <th className="valorunidade-header-print"> {convertetobrl(itens?.preco_venda)}</th>
                      <th className="quantidade-header-print"> {itens?.quantity}</th>
                      <th className="total-header-print"> {convertetobrl(itens?.quantity * itens?.preco_venda)}</th>
                    </tr>))
                  }  
                </div>
              </tbody>

              {/* <tfoot>  
                <div style={{textAlign: 'center'}}>----</div>
              </tfoot> */}

          </table>

          <table> 
              <thead className="header-cliente-print">
                <tr>
                  <td style={{textAlign: 'center', width:'21cm', fontSize:'12px'}}>MMV Comércio de Bike e Peças Eireli | CNPJ: 35.932.506/0001-13</td>
                </tr>
              </thead>
          </table>

      </div>
  )
});