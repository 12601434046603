import React from 'react';

import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { MenuLeft } from '../../components/MenuLeft';

import { VendedoresComponente } from '../../components/Vendedores';


import './vendedores.css'

export function Vendedores() {

  const { usuario } = useUsuarios();

  return(
    <>
    <Header/>
        <div id="page-vendedores">
          <div id="sidebar">
            {usuario?.permissao === 'superUser' &&  <MenuLeft/> }
          </div>
          {usuario?.permissao === 'superUser' &&
            <div id="principal-vendedores">
            <h5>Lista de Vendedores</h5>
            <VendedoresComponente />
            </div>
          }
        </div>
    </>
  );
}
