import React, { useContext, useEffect } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';

import { ShoppingListContext } from '../../contexts/ShoppingListContext';
import { Bag } from '../../components/Bag';

import { FaCartArrowDown } from 'react-icons/fa';

import 'react-pro-sidebar/dist/css/styles.css';
import './style.scss'

export function MenuRight() {

  const {setCartIsOpen, cartIsOpen, productsCount, shoppingList } = useContext(ShoppingListContext);

  function handleOpen(){
    setCartIsOpen(!cartIsOpen);
    setCartIsOpen(!cartIsOpen)
  }

  useEffect(() => {    
    if(productsCount > 0){
      setCartIsOpen(true)
    }else{
      setCartIsOpen(false)
    }  
    return () => {}
  }, [productsCount, setCartIsOpen])
  

  return(

    <ProSidebar  width={340} collapsed={!cartIsOpen}>

        <Menu iconShape="round">
          <MenuItem style={{color:'#fff', fontSize:'20px', fontWeight:'bold'}}
            icon={<FaCartArrowDown style={{color:'#B08653', fontSize:'30px', fontWeight:'bold',}}/>} 
            onClick={handleOpen}>
              <strong>Itens Orçamento ( {shoppingList.length} )</strong>
          </MenuItem>        
        </Menu>

        {cartIsOpen && <Bag/>}

    </ProSidebar>
  )

}

// https://reactjsexample.com/customizable-and-responsive-react-sidebar-library-with-dropdown-menus/