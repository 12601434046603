import React from 'react';
import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { MenuLeft } from '../../components/MenuLeft';
import { MenuLeftCliente } from '../../components/MenuLeftCliente';
import { CategoriasComponent } from '../../components/Categorias'

import './categorias.css'

export function Categorias() {

  const { usuario } = useUsuarios();

  return(
    <>
    <Header/>
        <div id="page-categorias">
            <div id="lateral">
              {usuario?.permissao === 'superUser' ? <MenuLeft/> : usuario?.analise === true &&  <MenuLeftCliente/>}
            </div>

            {usuario?.analise === true && 
              <div id="principal-categorias">            
                <CategoriasComponent/>                      
              </div>
            }

            {(usuario?.analise === false || usuario?.analise === 'removido') && 
              <div id="principal-aguarde">            
                <h5>Seu cadastro está em análise,<br/> volte novamente mais tarde...</h5>            
              </div>
            }

        </div>
    </>
  );
}

