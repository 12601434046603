import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Button, Collapse, Dropdown } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { database } from '../../services/firebase';

import { usePedidos } from '../../contexts/PedidosContext';
import { useVendedores } from '../../contexts/VendedoresListContext';

import { FaRegTrashAlt, FaRegUser, FaRegClock, FaRegCheckCircle, FaListUl, FaListOl, FaArrowsAltH, FaUserTie, FaPrint } from 'react-icons/fa';
//import { BiMailSend, BiPrinter } from "react-icons/bi";
import './pedidoscomponente.css';

import ReactToPrint from "react-to-print";
import { ComponentToPrint } from './ComponentToPrint';

export function PedidosComponent() {
  const location = useLocation();
  const { pedidosList, somaaprovados, somapendentes, somaexcluidos } = usePedidos();
  const { vendedoresList } = useVendedores();
  const [filtroPendente, setFiltroPendente] = useState("Pendente")
  // eslint-disable-next-line
  const [tipoFiltro, setTipoFiltro] = useState('status');
  const [buscar, setBuscar] = useState('');
  const [search, setSearch] = useState('');
  const [buscarvendedor, setBuscarVendedor] = useState('');
  // eslint-disable-next-line
  const [buscarValor, setBuscarValor] = useState('');
  const [mostrarpedido, setMostrarPedido] = useState(false);

  useEffect(() => {
    if(location !== undefined ){
      setFiltroPendente(location?.state?.statusdopedido);}    
  }, [location]);

  // eslint-disable-next-line
  const listapendentes = pedido => pedido[tipoFiltro] == filtroPendente;
  const pedidosfiltrados = pedidosList.filter(listapendentes)
  const contadorpedidos = pedidosfiltrados?.length;

  const maisfiltrados = useMemo(() => {  
    if (!buscar) return pedidosfiltrados;
   // eslint-disable-next-line
   return pedidosfiltrados.filter(pedido => pedido?.numerodopedido == buscar);   
  }, [buscar, pedidosfiltrados]);
  const filtradospornome = useMemo(() => {
    if (!search) return maisfiltrados;
    return maisfiltrados.filter((pedido) => {
      return (
          pedido.cliente.razaosocial.toUpperCase().includes(search.toUpperCase())
      );
    });
  }, [search, maisfiltrados]);
  const filtradosporvendedor = useMemo(() => {
  if (!buscarvendedor) return filtradospornome;
  return filtradospornome.filter((pedido) => {
    return (
        pedido.vendedor.toUpperCase().includes(buscarvendedor.toUpperCase())
    );
  });
  }, [buscarvendedor, filtradospornome ]);
  const filtradosporvalor = useMemo(() => {
    if (!buscarValor) return filtradosporvendedor;
    return filtradosporvendedor.filter((pedido) => {
      return (
        // eslint-disable-next-line
        parseInt(pedido.total) == buscarValor
      );
  });

  }, [buscarValor, filtradosporvendedor ]);

  filtradosporvalor.reverse();

  async function handleFiltrarPendentes(){
    setFiltroPendente("Pendente");
  }
  async function handleFiltrarAprovados(){
    setFiltroPendente("Aprovado");
  }
  async function handleFiltrarExcluidos(){
    setFiltroPendente("Excluido");
  }
  useEffect(() => {
    let t = setTimeout(() => {
      if(filtroPendente === 'Pendente' && filtradosporvalor.length < 1) {
        handleFiltrarAprovados();
      }
    }, 100);

    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [filtroPendente]);

  async function handleAprovePedido(routeName) {
    const pedido = database.ref(`/pedidos/`).child(`${routeName}/`);
      confirmAlert({
        title: `Aprovar pedido?`,
        message: `Realmente deseja aprovar o pedido ${routeName}?`,
        buttons: [
          {
            label: "SIM",
            onClick: async () =>{
              
              await pedido.update({
                status:"Aprovado"
              });

              // criar chamada a API para envio de email pegando user, status do pedido e numero.

            } 
          },
          {
            label: "NÃO APROVAR"
          }
        ]
      });
  }
  async function handleReprovePedido(routeName) {
    const pedido = database.ref(`/pedidos/`).child(`${routeName}/`);
      confirmAlert({
        title: `Analisar pedido?`,
        message: `Realmente deseja voltar à análise o pedido ${routeName}?`,
        buttons: [
          {
            label: "SIM",
            onClick: async () =>{
              await pedido.update({
                status:"Pendente"
              });
            } 
          },
          {
            label: "NÃO"
          }
        ]
      });
  }
  async function handleRemovePedido(routeName) {   
    const pedido = database.ref(`/pedidos/`).child(`${routeName}/`);
      confirmAlert({
        title: `excluir pedido?`,
        message: `Realmente deseja excluir o pedido ${routeName}?`,
        buttons: [
          {
            label: "SIM",
            onClick: async () =>{
              await pedido.update({
                status:"Excluido"
              });
            } 
          },
          {
            label: "NÃO EXCLIR"
          }
        ]
      });
  }
  async function handleAlterarVendedor(routeName, vendedorlabel, vendedorvalue){
    const pedido = database.ref(`/pedidos/`).child(`${routeName}/`);

    confirmAlert({
      title: `Alterar o Vendedor?`,
      message: `Realmente deseja alterar o vendedor do pedido ${routeName}?`,
      buttons: [
        {
          label: "SIM",
          onClick: async () =>{
            await pedido.update({
              idvendedor:vendedorvalue,
              vendedor:vendedorlabel
            });
          }
        },
        {
          label: "NÃO Alterar"
        }
      ]
    });
  }

  function stoqueround(x) {
    return Number.parseFloat(x).toFixed(0);
  }
  function convertetobrl(x) {
    return Number.parseFloat(x).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(",", ".");
  }
  function handleMostrarPedido(id){
    setMostrarPedido(prevState => (
      {...prevState, [id]: !prevState[id]}
    ))
    //https://stackoverflow.com/questions/62454545/how-to-collapse-only-one-div-when-using-react-and-map
  }

  const itemsRef = useRef([]);
  useMemo(() => {
    itemsRef.current = itemsRef.current.slice(0, filtradosporvalor.length);
    //console.log(itemsRef);
  }, [filtradosporvalor.length]);

  
  return(
  
    <>

      <div className="container-fluid" id="filtrinhos">

          <div className="row">

          <div className="col-sm-3 col-md-3 col-xs-12 col-lg-2">
              <div className="inputfiltropedidos">
                <input id="inputfiltropedidos"
                  type="text" 
                  placeholder="pedido" 
                  disabled = {false}
                  onChange={event => setBuscar(event.target.value)}
                  value={buscar}
                  autoComplete="off"/>                          
              </div>
            </div>

            <div className="col-sm-4 col-md-4 col-xs-12 col-lg-4">
              <div className="inputfiltropedidos">
                <input id="inputfiltropedidos"
                  type="text" 
                  placeholder="razão social" 
                  disabled = {false}                
                  onChange={event => setSearch(event.target.value.toUpperCase())}
                  value={search}/>                          
              </div>
            </div>

            <div className="col-sm-3 col-md-3 col-xs-12 col-lg-3">
              <div className="inputfiltropedidos">
                <input  id="inputfiltropedidos"
                  type="text" 
                  placeholder="vendedor" 
                  disabled = {false}                
                  onChange={event => setBuscarVendedor(event.target.value.toUpperCase())}
                  value={buscarvendedor}/>                          
              </div>
            </div>

            {/* <div className="col-sm-2 col-md-2 col-xs-12 col-lg-2">
              <div className="inputfiltropedidos">
                <input id="inputfiltropedidos"
                  type="number" 
                  placeholder="valor" 
                  disabled = {false}                
                  onChange={event => setBuscarValor(event.target.value)}
                  value={buscarValor} 
                  autoComplete="off"/>                          
              </div>
            </div> */}

            <div className="col-sm-3 col-md-3 col-xs-12 col-lg-3">
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="dropdownfilters">
                      {filtroPendente === 'Pendente' &&
                      <p style={{color:'#f90'}}><FaRegClock style={{color:'#f90'}}/> 
                        <span style={{color:'#f90'}}> Pendentes ({contadorpedidos}) - {convertetobrl(somapendentes)}</span>
                      </p>}
                      {filtroPendente === 'Aprovado' &&
                      <p style={{color:'#3c8b0f'}}><FaRegCheckCircle style={{color:'#3c8b0f'}}/> 
                        <span style={{color:'#3c8b0f'}}> Aprovados ({contadorpedidos}) - {convertetobrl(somaaprovados)}</span>
                      </p>}
                      {filtroPendente === 'Excluido' &&
                      <p style={{color:'#f00'}}><FaRegTrashAlt style={{color:'#f00'}}/> 
                        <span style={{color:'#f00'}}> Excluídos ({contadorpedidos}) - {convertetobrl(somaexcluidos)}</span>
                      </p>}                    
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleFiltrarAprovados} style={{color:'#3c8b0f'}}><FaRegCheckCircle/> Aprovados</Dropdown.Item>
                      <Dropdown.Item onClick={handleFiltrarPendentes} style={{color:'#f90'}}><FaRegClock/> Pendentes</Dropdown.Item>
                      <Dropdown.Item onClick={handleFiltrarExcluidos} style={{color:'#f00'}}><FaRegTrashAlt/> Excluídos</Dropdown.Item>                                                       
                    </Dropdown.Menu>
                </Dropdown>
            </div>

          </div>
      </div>

      <div className="container-fluid" id="pedidoscontent">

        {filtradosporvalor.map((pedidos, idx) => (
          <div className="container-fluid" id="pedidocontainerfluid" pedidos={pedidos} key={pedidos?.numerodopedido} >  
            <>          
              <div className="row cinza" style={{borderRadius:5}} >
                <div className="col-lg-6 col-sm-6 alinharpedidocliente">

                  <Button className="btnCollapse" variant="secondary" id="showorder-su"
                    onClick={() => handleMostrarPedido(pedidos?.numerodopedido)}
                    aria-controls="divpararenderizar"
                    aria-expanded={mostrarpedido}> 
                    <p><i><FaListUl/></i> <span>Pedido</span> <strong style={{color:'#666'}}>{pedidos?.numerodopedido}</strong></p>
                  </Button>

                  <Button className="btnCollapse" variant="secondary" id="valor-su"  
                  onClick={() => handleMostrarPedido(pedidos?.numerodopedido)}>
                   <p>{pedidos?.data}</p> 
                  </Button>

                  <Button className="btnCollapse" variant="secondary" id="empresa-su"
                    onClick={() => handleMostrarPedido(pedidos?.numerodopedido)}
                    aria-controls="divpararenderizar"
                    aria-expanded={mostrarpedido}> 
                    <p><i><FaRegUser style={{color:'#B08653'}}/></i> {pedidos?.cliente?.razaosocial} </p> 
                  </Button>

                </div>
              
                <div className="col-lg-6 col-sm-6">                 
                  <div className="alinhar">  

                    <Button className="btnCollapse" variant="secondary" id="vendedor-su"
                      onClick={() => handleMostrarPedido(pedidos?.numerodopedido)}>
                        <p>{pedidos?.vendedor}</p>
                    </Button>

                    <Dropdown style={{marginRight:20}}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="dropdownfilters btnCollapse" style={{marginTop:10, marginRight:10}}>
                          <p style={{fontSize:13, color:"#222"}}> <FaUserTie style={{color:"#B08653"}}/> <FaArrowsAltH/> <FaUserTie style={{color:"#3c8b0f"}}/></p>                   
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {vendedoresList?.map((vendedor, index) => (                         
                          <Dropdown.Item key={index} vendor={vendedor} 
                          onClick={() => handleAlterarVendedor(pedidos?.numerodopedido, vendedor.label, vendedor.value)}>                         
                          <p style={{fontSize:13, marginBottom:0, color:"#222"}}>{vendedor.label}</p> 
                          </Dropdown.Item>
                        ))}
                        </Dropdown.Menu>
                    </Dropdown>

                    <Button className="btnCollapse" variant="secondary" id="valor-su"
                      onClick={() => handleMostrarPedido(pedidos?.numerodopedido)}>
                        <p>{convertetobrl(pedidos?.total)}</p>
                    </Button>
                  
                    {pedidos.status === "Pendente" &&
                      <Button className="btnCollapse" variant="secondary" id="reprove-su"
                      onClick={() => handleAprovePedido(pedidos?.numerodopedido)}>
                        <p><i><FaRegClock/></i> Pendente</p>
                      </Button>
                    }
                    {pedidos.status === "Aprovado" &&
                      <Button className="btnCollapse"  variant="secondary" id="aprove-su"
                      onClick={() => handleReprovePedido(pedidos?.numerodopedido)}>
                        <p><i><FaRegCheckCircle/></i> Aprovado</p>
                      </Button>
                    }

                    <ReactToPrint                       
                      trigger={() => (
                        <Button className="btnCollapse" variant="secondary" id="print-su">
                          <p><i><FaPrint/></i> <span style={{fontWeight: 'bold', fontSize:12}}>PDF</span></p>
                        </Button>
                      )}
                      content={() => {                       
                        return itemsRef.current[idx];
                      }}
                      onBeforePrint={() => document.title = "MMV Distribuidora - Pedido de Orçamento"}
                      onAfterPrint={() => document.title = "MMV Distribuidora - Peças de Bike e Moto!"}
                    />
                    
                    <Button className="btnCollapse" variant="secondary" id="view-su"
                      onClick={() => handleMostrarPedido(pedidos?.numerodopedido)}
                      aria-controls="divpararenderizar"
                      aria-expanded={mostrarpedido}>
                        <p><i><FaListOl/></i><span>Itens</span> <strong style={{color:'#888'}}>{pedidos?.itens?.length}</strong></p> 
                    </Button>

                    {pedidos.status !== "Excluido" &&
                    <Button className="btnCollapse" variant="secondary" id="remove-su" 
                      onClick={() => handleRemovePedido(pedidos?.numerodopedido)}>
                        <p><i><FaRegTrashAlt/></i> <span>Excluir</span></p>
                    </Button>}

                  </div> 
                </div>
              </div>
              <Collapse in={mostrarpedido[pedidos?.numerodopedido]}>

              <div id="divpararenderizar" style={{paddingTop:20}}>   

                  <div className="row">                      
                      <div className="col-sm-3 col-xs-12">   
                        <p>Data: {pedidos?.data}<span style={{paddingLeft:20}}>Hora: {pedidos?.hora}</span></p> 
                        <p>Nome: {pedidos?.cliente?.razaosocial}</p>
                        <p>CNPJ: {pedidos?.cliente?.cnpj}</p>    
                        <p>Pedido: {pedidos?.numerodopedido}</p>    
                        <p>Status: {pedidos?.status}</p>                   
                      </div>
                      <div className="col-sm-3">
                        <p>Endereço: {pedidos?.cliente?.endereco?.logradouro}, {pedidos?.cliente?.endereco?.numero} <span>{pedidos?.cliente?.endereco?.complemento}</span></p>   
                        <p>Bairro: {pedidos?.cliente?.endereco?.bairro}</p> 
                        <p>Cidade: {pedidos?.cliente?.endereco?.cidade}</p>
                        <p>Estado: {pedidos?.cliente?.endereco?.estado} </p>  
                        <p>CEP: {pedidos?.cliente?.endereco?.cep}</p>             
                      </div>   
                      <div className="col-sm-3">
                        <p>Contato: {pedidos?.cliente?.nome}</p>
                        <p>E-mail: {pedidos?.cliente?.email}</p>  
                        <p>Celular: {pedidos?.cliente?.celular}</p>                           
                        <p>Tel: {pedidos?.cliente?.telefone}</p>                
                      </div>
                      <div className="col-sm-3">
                        <p>Vendedor: {pedidos?.vendedor}</p> 
                        {/* <p>E-mail: {pedidos?.cliente?.emailvendedor}</p>     
                        <p>Tel: {pedidos?.cliente?.telefonevendedor}</p> */}
                      </div>
                  </div>
                  <div className="row cinzaescuro" >
                      <div className="col-sm-1">
                        <p>Código</p>
                      </div>  
                      <div className="col-sm-5">
                        <p>Nome</p>
                      </div>
                      {/* <div className="col-sm-1">
                        <p>Un</p>
                      </div> */}
                      <div className="col-sm-2">
                        <p>Preço Unid.</p>
                      </div>    
                      <div className="col-sm-1">
                        <p>Qtde.</p>
                      </div>   
                      <div className="col-sm-2">
                        <p>Estoque</p>
                      </div> 
                      <div className="col-sm-1">
                        <p>Total</p>
                      </div>  
                  </div> 

                  {pedidos?.itens?.map((itens, idx) => (
                  <div className="row listaitens" style={{ borderBottomLeftRadius:10, borderBottomRightRadius:10 }} itens={itens} key={itens.CD_PRODUTO}>
                    <div className="col-sm-1">
                      <p>{itens?.CD_PRODUTO}</p>
                    </div> 
                    <div className="col-sm-5">
                      <p>{itens?.DESCRICAO}</p>
                    </div>   
                    {/* <div className="col-sm-1">
                      <p>{itens?.package}</p>
                    </div> */}
                    <div className="col-sm-2">
                      <p>{convertetobrl(itens?.preco_venda)}</p>
                    </div>      
                    <div className="col-sm-1">
                      <p>{itens?.quantity}</p>
                    </div> 
                    <div className="col-sm-2">
                      <p>{stoqueround(itens?.estoque_saldo)}</p> 
                    </div> 
                    <div className="col-sm-1">
                      <p>{convertetobrl(itens?.quantity * itens?.preco_venda)}</p>
                    </div>  
                  </div>                       
                  ))} 

                  {/* <div className="row cinzaescuro">
                    <div className="col-sm-12">
                      <p style={{textAlign: 'center'}}>***** INFORMAÇÕES ADICIONAIS *****</p>                
                    </div>
                  </div> 

                  <div className="row" style={{padding:10}}>
                      <div className="col-sm-3">
                        <p>Transportadora: JADLOG</p>  
                        <p>Data de Entrega: {pedidos?.data}</p>
                      </div>
                      <div className="col-sm-3">
                        <p>Peso Bruto Total:</p>
                        <p>Quantidade de Itens:</p>              
                      </div>
                      <div className="col-sm-3">
                        <p>Condições de Pagamento</p>  
                        <p>30/30/90 CHEQUE</p>          
                      </div>
                      <div className="col-sm-3">
                        <p>Itens: {pedidos?.itens?.length}</p> 
                        <p></p>                                          
                      </div>
                  </div>  */}

                  <div className="row cinza">
                    <div className="col-sm-12">
                      <p style={{textAlign: 'center', padding:10}}>MMV Comércio de Bike e Peças Eireli | CNPJ: 35.932.506/0001-13 </p>                    
                    </div>
                  </div> 

              </div> 

              </Collapse>


              <div style={{display:"none"}}>
                <ComponentToPrint ref={(pedidos) => (itemsRef.current[idx] = pedidos)} 
                  numerodopedido={pedidos?.numerodopedido}
                  cliente={pedidos?.cliente}
                  data={pedidos?.data}
                  hora={pedidos?.hora}
                  vendedor={pedidos?.vendedor}
                  total={convertetobrl(pedidos?.total)}
                  status={pedidos?.status}
                  listaitens={pedidos?.itens}                  
                />
              </div>            
            </>
          </div>
        ))}

      </div>
    </>
  )
}


