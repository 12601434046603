import React, { useContext, useState, useEffect } from "react"

import { database } from '../services/firebase';

const CountContext = React.createContext()

export function useCount() {
  return useContext(CountContext);
}

export function CountProvider({ children }) {

  const [pedidosCount, setPedidosCount] = useState(0);
  const [totalOrcamento, setTotalOrcamento] = useState(0);
  const [codigovendedor, setCodigoVendedor] = useState(0);
  const [codigocliente, setCodigoCliente] = useState(0);

  useEffect(() => {
    database.ref(`codigocliente/`).on('value', snap => {
      setCodigoCliente(snap.val());
    });
  }, [codigocliente]);

  useEffect(() => {
    database.ref(`codigovendedor/`).on('value', snap => {
      setCodigoVendedor(snap.val());
    });
  }, [codigovendedor]);

  useEffect(() => {
    database.ref(`numerodopedido/`).on('value', snap => {
      setPedidosCount(snap.val());
    });
  }, [pedidosCount]);

  useEffect(() => {
    database.ref(`totalorcamento/`).on('value', snap => {
      setTotalOrcamento(snap.val());
    });
  }, [totalOrcamento]);

  const totalorcamentoformatado = totalOrcamento.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(".", ".");
  //https://www.codegrepper.com/code-examples/javascript/formata%C3%A7%C3%A3o+de+moeda+real+reactjs

    const value = {
      codigocliente,
      codigovendedor,
      pedidosCount,
      totalOrcamento,
      totalorcamentoformatado
    }

  return (
    <CountContext.Provider value={value}>
      {children}
    </CountContext.Provider>
  )

}