import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { MenuLeftCliente } from '../../components/MenuLeftCliente';
import { PedidosVendedorComponent } from '../../components/PedidosVendedor';
import './pedidosvendedor.css'

export function PedidosVendedor() {
  const history = useHistory();

  const { usuario } = useUsuarios();

  useEffect(() => {
    if(!usuario){
      history.push('/')
    }
    return () => {  
    }
  }, [usuario, history])


  return(
    <>
    <Header/>
        <div id="page-pedidos-vendedor">
          <div id="lateral">
            {usuario?.permissao === 'vendedor' && usuario.analise === true &&
            <MenuLeftCliente/>
            }
          </div>
          <div id="principal-pedidos-vendedor">
            {/* <h5>Pedidos da MMV</h5> */}
            {usuario?.permissao === 'vendedor' && usuario.analise === true &&
              <PedidosVendedorComponent/>
            }            
          </div>
        </div>
    </>
  );
}

