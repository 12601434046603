import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCategorias } from '../../contexts/CategoriasContext'; 

import './categoriascomponente.css';

export function CategoriasComponent() {
  const history = useHistory();

  const { categoriasData } = useCategorias(); 

  function handleGoToCategory(routeName) {
    history.push({
      pathname: '/produtos',
      state: { nomedacategoria: `${routeName}` }
    });
  }

  return(
    <div className="grid-wrapper">      
      {categoriasData?.map(categorias => {
        return <div  className="child"  key={categorias.CD_GRUPO}
        onClick={() => handleGoToCategory([categorias.CD_GRUPO])}>
          <h2>{categorias.DESCRICAO}</h2>            
        </div>
      })}
    </div>
  );
}