import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';

import { ShoppingListContext } from '../../contexts/ShoppingListContext';
import { Bag } from '../../components/Bag';

import { FaCartArrowDown } from 'react-icons/fa';

import 'react-pro-sidebar/dist/css/styles.css';
import './style.scss'

export function MenuRightMobile() {

  const history = useHistory();

  const { shoppingList } = useContext(ShoppingListContext);

  useEffect(() => {
    let t = setTimeout(() => {
      if(shoppingList.length === 0) {
        history.push('/produtos')
      }
    }, 500);

    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [shoppingList]);


  return(

    <ProSidebar className="tamanhodocarrinho">

        <Menu iconShape="round">
          <MenuItem style={{color:'#fff', fontSize:'20px', fontWeight:'bold'}}
            icon={<FaCartArrowDown style={{color:'#B08653', fontSize:'30px', fontWeight:'bold',}}/>}>
              <strong>Itens Orçamento ( {shoppingList.length} )</strong>
          </MenuItem>        
        </Menu>

        <Bag/>

    </ProSidebar>
  )

}

// https://reactjsexample.com/customizable-and-responsive-react-sidebar-library-with-dropdown-menus/