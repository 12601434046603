import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Login } from "./screens/Login";

import { Cadastro } from './screens/Cadastro';
import { CadastroDeVendedores } from './screens/CadastroVendedores';
import { ObrigadoCadastroVendedor } from "./screens/ObrigadoCadastroVendedor";

import { CadastroExterno } from './screens/CadastroExterno';
import { ObrigadoCadastro } from './screens/ObrigadoCadastro';

import { Analise } from './screens/Analise';
import { AnaliseVendedores } from './screens/AnaliseVendedores';

import { Home } from './screens/Home';

import { Clientes } from './screens/Clientes'
import { Vendedores } from './screens/Vendedores'

import { Categorias } from './screens/Categorias';
import { Produtos } from './screens/Produtos'
import { Carrinho } from './screens/Carrinho'

import { Pedidos } from './screens/Pedidos'
import { PedidosVendedor } from './screens/PedidosVendedor'

import { PedidosCliente } from './screens/PedidosCliente'
import { Obrigado } from './screens/Obrigado';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/home" exact component={Home} /> 
        <Route path="/cadastrocliente" exact component={CadastroExterno}/>     

        <Route path="/cadastro" exact component={Cadastro}/>        
        <Route path="/obrigadocadastro" exact component={ObrigadoCadastro}/>

        <Route path="/cadastrovendedores" exact component={CadastroDeVendedores}/>
        <Route path="/obrigadocadastrovendedor" exact component={ObrigadoCadastroVendedor}/>
        <Route path="/analise" exact component={Analise}/>   
           
        <Route path="/analisevendedores" exact component={AnaliseVendedores}/>
        <Route path="/clientes" exact component={Clientes}/>

        <Route path="/vendedores" exact component={Vendedores}/>

        <Route path="/categorias" exact component={Categorias}/>
        <Route path="/produtos" exact component={Produtos}/>
        <Route path="/carrinho" exact component={Carrinho}/>

        <Route path="/pedidos" exact component={Pedidos}/>
        <Route path="/pedidoscliente" exact component={PedidosCliente}/>
        <Route path="/pedidosvendedor" exact component={PedidosVendedor}/>
        <Route path="/obrigado" exact component={Obrigado}/>

      </Switch>
    </BrowserRouter>
  );
}