import React from 'react';

import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { MenuLeft } from '../../components/MenuLeft';
import { ClientesAnalise } from '../../components/ClientesAnalise';
import './analise.css'

export function Analise() {

  const { usuario } = useUsuarios();

  return(
    <>
    <Header/>
        <div id="page-analise-clientes">
          <div id="lateral">
            {usuario?.permissao === 'superUser' &&   <MenuLeft/> }
          </div>
            {usuario?.permissao === 'superUser' &&
              <div id="principal-analise-clientes">
              <h5>Análise cadastral</h5>
                <ClientesAnalise/>
              </div>
            }
        </div>
    </>
  );
}
