import React from 'react';
import { useCount } from '../../../contexts/CountContext';

export function VendedoresCount() {
  const {codigovendedor}  = useCount();

  return(
    <p style={{textAlign: 'center'}}><strong>{codigovendedor}</strong><br/>Vendedores</p>
  )

}