import React, { useContext, useState, useEffect } from "react"

import { database } from '../services/firebase';
import { useAuth } from "./AuthContext";

const UsuariosContext = React.createContext()

export function useUsuarios() {
  return useContext(UsuariosContext);
}

export function UsuarioProvider({ children }) {

  const { currentUser, logout } = useAuth();
  const [ usuario, setUsuario ] = useState('');
  const [ error, setError ] = useState('');

  useEffect(() => {
    const usuarioRef = database.ref(`/usuarios/${currentUser?.uid}`); 
    usuarioRef.on('value', (snapshot) => {
      const usuario = snapshot.val();
      setUsuario(usuario);
    });
    return () => {
      usuarioRef.off('value'); 
    }
  }, [currentUser]);


  async function handleLogoutSubmit(event) {
    event.preventDefault()
    try {
      setError('')
      await logout();

    } catch(error) {
      setError(`Falha no Logout`);
    }
  }

  const value = {
    usuario,
    currentUser,
    error,
    handleLogoutSubmit,
  }

  return (
    <UsuariosContext.Provider value={value}>
      {children}
    </UsuariosContext.Provider>
  )

}