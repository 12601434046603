import React from 'react';

import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { MenuLeft } from '../../components/MenuLeft';
import { MenuLeftCliente } from '../../components/MenuLeftCliente';
import { MenuRight } from '../../components/MenuRight';
import { ProdutosSQLComponent } from '../../components/Produtos'


import './produtos.css'

export function Produtos() {

  const { usuario } = useUsuarios();

  return(
    <>
      <Header/>
        <div id="page-produtos">
          <div id="lateral">
           {usuario?.permissao === 'superUser' ? <MenuLeft/> : <MenuLeftCliente/>}
          </div>
          {usuario?.analise === true && 
            <div id="principal-produtos">
              <ProdutosSQLComponent/>
            </div>
          }
          {usuario?.analise === true && 
            <div id="bag">
              <MenuRight/>
            </div>
          }
        </div>
    </>
  );
}

