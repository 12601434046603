import React from 'react';
import { useCount } from '../../../contexts/CountContext'

export function OrcamentosCount() {
  const { totalorcamentoformatado } = useCount();

  return(
    <p style={{textAlign: 'center'}}>Total Orçamentos<br/> <strong> {totalorcamentoformatado}</strong></p>
  );
}
