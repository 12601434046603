import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";


import { useUsuarios} from '../../contexts/UsuarioContext';
import { Header }  from '../../components/Header';
import './obrigadocadastro.css'

export function ObrigadoCadastro() {

  const location = useLocation();
  const history = useHistory();
  const { handleLogoutSubmit } = useUsuarios();

  const [cadastroconfirmado, setPedidoConfirmado] = useState(0);

  useEffect(() => {
    setPedidoConfirmado(location?.state?.cadastrodocliente);

    confirmAlert({
      title: `Olá ${cadastroconfirmado}`,
      message: `Seu cadastro foi realizado com sucesso.`,
      buttons: [
        {
          label: "OK",
          onClick: () => {}
        }
      ]
    });

  return () => {console.log('obrigado Cadastro Cliente')}

 }, [ location, cadastroconfirmado, history ]);

  return(
    <>
      <Header/>
      <div id="page-obrigado">
        <div id="lateral">
        </div>
        <div id="principal-obrigado">        
          <p>Obrigado por se Cadastrar<br/>
            em breve analisaremos seus dados.<br/> 
            Para continuar, faça Logout!
          </p> 
          <button className="btnViewCatalog" 
           onClick={handleLogoutSubmit}>
             LOGOUT
          </button>
        </div>
      </div>
    </>
  );
}

