import React from 'react';
import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { CadastroCliente } from '../../components/Cadastro';
import { MenuLeft } from '../../components/MenuLeft';

import './cadastro.css'

export function Cadastro() {

  const { usuario } = useUsuarios();

  return(
    <>
    <Header/>
        <div id="page-cadastro">
          <div id="sidebar">
          {usuario?.permissao === 'superUser' && <MenuLeft/>}
          </div>
          <div id="principal-cadastro">
          <h5>Cadastro de clientes</h5>
          {usuario?.permissao === 'superUser' && <CadastroCliente/>}            
          </div>
        </div>
    </>
  );
}
