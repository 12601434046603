import React from 'react';
import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { MenuLeft } from '../../components/MenuLeft';
import { VendedoresAnaliseComponente } from '../../components/VendedoresAnalise';

import './analisevendedores.css'

export function AnaliseVendedores() {
  const { usuario } = useUsuarios();

  return(
    <>
    <Header/>
        <div id="page-analise-vendedores">
          <div id="lateral">
            {usuario?.permissao === 'superUser' &&   <MenuLeft/> }
          </div>
            {usuario?.permissao === 'superUser' &&
              <div id="principal-analise-vendedores">
              <h5>Análise Vendedores</h5>
              <VendedoresAnaliseComponente />
              </div>
            }
        </div>
    </>
  );
}
