import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import { database } from '../../services/firebase';
import { useAuth } from "../../contexts/AuthContext";
import { useCount } from '../../contexts/CountContext';

import './cadastrovendedores.css'


export function CadastroVendedores(){
  const history = useHistory();
  const { signup } = useAuth();

  const { codigovendedor } = useCount();

  // eslint-disable-next-line
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [nome, setNome] = useState('');
  const [erronome, setErroNome] = useState('');

  const [email, setEmail] = useState('');
  const [erroemail, setErroEmail] = useState('');

  const [password, setPassword] = useState('');
  const [erropassword, setErroPassword] = useState('');

  const [celular, setCelular] = useState('');
  const [errocelular, setErroCelular] = useState('');


  function validateNome(){
    if(nome.length < 3 ){
      setErroNome('campo obrigatório')
      return;
    }else{
      setErroNome('')
    } 
   }

   function ValidateEmail() {

    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email))
     { setErroEmail('')
     }else{
       setErroEmail('digite um e-mail válido!')
     }
   }

  function validatePassword(){
    if(password.length < 8 ){
      setErroPassword('mínimo 8 dígitos')
      return;
    }else{
      setErroPassword('')
    } 
   }

  // (00) 00000-0000
  const maskPhone = value => {
    return value
      .replace(/\D/g,'')
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d{4})/, "$1-$2")
  };

   function validadeCelular(){
    const regex = new RegExp(/^(?:(?:\+|00)?(55)\s?)?(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{4})-?(\d{4}))$/); 
    if (regex.test(celular)) { 
      console.log("Telefone Válido");
      setErroCelular('')
    }
    else setErroCelular("número inválido");
  }


  async function handleSubmit(event) {

    event.preventDefault()

    try {
      setError("")
      setLoading(true)   
           
      if(nome === '' ||  celular === '' || email === ''|| password === ''){
        alert('Preencha os Campos Corretamente!')
        setLoading(false);
        return;      
      }else{

        const newVendedor = await signup(email, password);
        const newVendedorBd = database.ref(`usuarios/${newVendedor.user.uid}/`);
          const vendedor = { 
            uid: newVendedor.user.uid,
            email: newVendedor.user.email,
            nome:nome,
            superUser:false,
            permissao:"vendedor",
            analise:false,
            ultimologin:"nunca",
            senha:password,
            cod:codigovendedor + 1,
            celular:celular,
          }

        await newVendedorBd.set(vendedor);
        await database.ref('codigovendedor/').set(codigovendedor + 1);

        const vendedoresBd = database.ref(`vendedores/${newVendedor.user.uid}/`);
          const vendedorlist = {
            value: newVendedor.user.uid,
            label: nome,
          }
        await vendedoresBd.set(vendedorlist);

        setLoading(false);

        history.push({
          pathname: '/obrigadocadastrovendedor',
          state: { cadastrodovendedor: nome}
        });

      }
    
    }catch(e){
      switch(e.code) {
        case 'auth/email-already-in-use':
          alert('Email já utilizado');
          setEmail('')
          break;

        case 'auth/invalid-email':
          alert('Email Inválido');
          setEmail('')
          break;

        default:
      }
     //https://stackoverflow.com/questions/53129814/show-error-as-alert-on-firebase-authentication-with-react-native
     setLoading(false);
    }
}


  return(
    <Container fluid>

      {loading && <div className="aguarde"> <h1>...</h1> </div>}

      <form onSubmit={handleSubmit}>

      <h5>Cadastro Vendedores</h5>
      {/* <p>CODIGO ATUAL: {codigovendedor}</p> */}
      
      {!loading && <>

      <div className="row" style={{borderBottom: '1px dashed #d5d5d5', marginBottom:20,  paddingBottom:20}}> 
            {/* <p> 60049434000108 | 07786144000164 </p> */}

                  <div className="col-sm-3">
                      <div id="inputcreate">
                          <p><span style={{color:'red'}}>* </span>Vendedor</p>    
                          <input 
                            type="text" 
                            placeholder="digite seu nome"
                            onChange={event => setNome(event.target.value)}
                            onBlur={validateNome}
                            value={nome}
                          />
                          <p><span style={{color:'red'}}> {erronome} </span></p> 
                      </div>
                  </div>

                  <div className="col-sm-3" >
                      <div id="inputcreate">
                        <p><span style={{color:'red'}}>* </span>Celular com DDD</p>    
                        <input 
                          type="text" 
                          placeholder="(xx) 99999-0000"
                          onBlur={validadeCelular}
                          maxLength="14"
                          onChange={event => setCelular(maskPhone(event.target.value))}
                          value={celular}
                        />                       
                      <p><span style={{color:'red'}}> {errocelular} </span></p> 
                      </div>
                  </div>
                  <div className="col-sm-3" >
                      <div id="inputcreate">
                          <p><span style={{color:'red'}}>* </span>E-mail</p>    
                          <input 
                            type="text" 
                            placeholder="ex: email@empresa.com.br"
                            onChange={event => setEmail(event.target.value)}
                            onBlur={ValidateEmail}                          
                            value={email}
                            width="100%"
                          />
                          <p><span style={{color:'red'}}> {erroemail} </span></p> 
                      </div>
                  </div>
                  <div className="col-sm-3" >
                      <div id="inputcreate">
                          <p><span style={{color:'red'}}>* </span>Digite a senha</p>    
                          <input 
                            type="password"
                            placeholder="mínimo 8 dígitos"
                            onChange={event => setPassword(event.target.value)}
                            onBlur={validatePassword}
                            value={password}
                            //autoCorrect={false}
                            minLength="8"
                            width="100%"
                            autoComplete="new-password"
                          />
                          <p><span style={{color:'red'}}> {erropassword} </span></p> 
                      </div>
                  </div>
            </div>

            <div id="btnCadastrarvendedor">
              <button
                type="submit" 
                disabled={loading} 
                className="btnCriarConta" 
                style={{width: '100%', fontWeight:'bold'}}>
                CADASTRAR VENDEDOR
              </button>    
            </div>

        </>
      }

      </form>


        
    </Container>
  )
}