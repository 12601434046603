import React from 'react';
import { usePedidos } from '../../../contexts/PedidosContext';

export function PendentesCount() {
  const { pendentes, somapendentes } = usePedidos();

  function convertetobrl(x) {
    return Number.parseFloat(x).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(",", ".");
  }  

    return(
      <p style={{textAlign: 'center'}}><strong>{pendentes}</strong> <br/>Pedidos Pendentes<br/> <strong>{convertetobrl(somapendentes)}</strong>
      </p>
    )
}