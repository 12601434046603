import React from 'react';

import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { MenuLeft } from '../../components/MenuLeft';
import { ClientesAnalisados } from '../../components/Clientes';

import './clientes.css'

export function Clientes() {

  const { usuario } = useUsuarios();

  return(
    <>
    <Header/>
        <div id="page-clientes">
          <div id="sidebar">
            {usuario?.permissao === 'superUser' &&  <MenuLeft/> }
          </div>
          {usuario?.permissao === 'superUser' &&
            <div id="principal-clientes">
            <h5>Lista de Clientes</h5>
              <ClientesAnalisados/>
            </div>
          }
        </div>
    </>
  );
}
