import Routes from './routes';

import { AuthProvider } from '../src/contexts/AuthContext';
import { ShoppingListProvider } from './contexts/ShoppingListContext';
import { UsuarioProvider } from './contexts/UsuarioContext';
import { CategoriaProvider } from './contexts/CategoriasContext';
import { ProdutosProvider } from './contexts/ProdutosContext';
import { PedidosProvider } from './contexts/PedidosContext';
import { CountProvider } from './contexts/CountContext';
import { VendedoresProvider } from './contexts/VendedoresListContext'

import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <AuthProvider>     
        <UsuarioProvider>
          <VendedoresProvider>
            <PedidosProvider>
              <CategoriaProvider>
                <ProdutosProvider>                
                  <CountProvider>
                    <ShoppingListProvider>  
                        <Routes/>
                    </ShoppingListProvider>
                  </CountProvider>
                </ProdutosProvider>
              </CategoriaProvider>
            </PedidosProvider>
          </VendedoresProvider>
        </UsuarioProvider>
    </AuthProvider>    
  );
}

export default App;