import React, { useContext, useState, useEffect } from "react"
import { database } from '../services/firebase';

const PedidosContext = React.createContext()

export function usePedidos() {
  return useContext(PedidosContext);
}

export function PedidosProvider({ children }) {

  const [pedidosList, setPedidosList] = useState([]);

  const [aprovados, setAprovados] = useState([]);
  const [pendentes, setPendentes] = useState([]);
  const [excluidos, setExcluidos] = useState([]);

  useEffect(() => {  
    const pedidosRef = database.ref(`/pedidos/`);
      pedidosRef.on('value', (snapshot) => {      
        const pedidos = snapshot.val();
        const pedidosList = [];
        for (let idpedidos in pedidos) {
          pedidosList.push ({ idpedidos, ...pedidos[idpedidos] });
        }
        setPedidosList(pedidosList);
      });
      return () => {pedidosRef.off('value');}
  }, []);

  const excluidosfilter = pedido => pedido.status === "Excluido";
  const pedidosexcluidos = pedidosList?.filter(excluidosfilter);

  const pendentesfilter = pedido => pedido.status === "Pendente";
  const pedidospendentes = pedidosList?.filter(pendentesfilter);

  const aprovadosfilter = pedido => pedido.status === "Aprovado";
  const pedidosaprovados = pedidosList?.filter(aprovadosfilter);


  useEffect(() => {
    if(pedidosList){
      setExcluidos(pedidosexcluidos.length);
      setPendentes(pedidospendentes.length);
      setAprovados(pedidosaprovados.length);
    }
    return () => {
    }
  }, [pedidosaprovados, pedidospendentes, pedidosexcluidos, pedidosList])


  const somapendentes = pedidospendentes.reduce((acc, x) => acc + x.total, 0);
  const somaaprovados = pedidosaprovados.reduce((acc, x) => acc + x.total, 0);
  const somaexcluidos = pedidosexcluidos.reduce((acc, x) => acc + x.total, 0);

    const value = {
      pedidosList,
      excluidos,
      pendentes,
      aprovados,
      somapendentes,
      somaaprovados,
      somaexcluidos
    }

  return (
    <PedidosContext.Provider value={value}>
      {children}
    </PedidosContext.Provider>
  )

}