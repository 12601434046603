import React, { useEffect, useState } from 'react';

import api from '../../../services/api';

export function CategoriasCount() {

  const [categoriasData, setCategoriasData] = useState([]);

  useEffect(() => {
    let isSubscribed = true
    async function getCategoriasSql() {
      const response = await api.get('/categorias', {
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(categoriasData)
      });
      if (isSubscribed) {
        setCategoriasData(response.data);
      }        
    };
    getCategoriasSql();
    return () => isSubscribed = false
    // eslint-disable-next-line 
  }, []);

  return(
    <p style={{textAlign: 'center'}}><strong>{categoriasData?.length}</strong><br/>Categorias</p>
  )

}