import React, { useContext } from 'react';
import './card.scss';

import { ShoppingListContext } from '../../../contexts/ShoppingListContext';
import { useUsuarios } from '../../../contexts/UsuarioContext'; 

export default function Card(props) {
  
  const { usuario } = useUsuarios();
  const { shoppingList, setShoppingList, setCartIsOpen, setProductsCount } = useContext(ShoppingListContext);
  const { CD_PRODUTO, DESCRICAO, preco_venda } = props.product;

  const addItem = () => {
    setCartIsOpen(true);
    const product_array = shoppingList.filter(p => p.CD_PRODUTO === CD_PRODUTO);
    const product_copy = props.product;
   
    if (product_array.length > 0) {
      setProductsCount(productCount => productCount + 1);
      let new_state = shoppingList.map(p => {
        if (p.CD_PRODUTO === CD_PRODUTO) {
          p.quantity += 1;
          return { ...p };
        } else return { ...p };
      });
      setShoppingList(new_state);
    }else {
      setProductsCount(productCount => productCount + 1);
      product_copy.quantity = 1;
      setShoppingList([...shoppingList, product_copy]);
    }
  };

  // function financial(x) {
  //   return Number.parseFloat(x).toFixed(2);
  // }

  function convertetobrl(x) {
    return Number.parseFloat(x).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(",", ".");
  }

  return (
    <div className="card__wrapper">

      <div className="card">
        <div className="imgproduct">
          <img style={{maxHeight: '150px', maxWidth:'150px'}} loading="lazy"       
          src={
            `https://mmvdistribuidora.com.br/api/produtos/images/${CD_PRODUTO}.jpg` ? 
            `https://mmvdistribuidora.com.br/api/produtos/images/${CD_PRODUTO}.jpg` : 
            `https://mmvdistribuidora.com.br/api/produtos/images/carregando.png`
          } alt={DESCRICAO} width="150px" maxheight="150px"
          
          onError={(e) => {
            e.target.onerror = 404
            e.target.onerror = null;
            e.target.src = 'https://mmvdistribuidora.com.br/api/produtos/images/default.jpg'
          }}   
          /> 
        </div>

        <div className="container">
          <p style={{textAlign: 'left'}}>Código: {CD_PRODUTO}</p>
          <div className="nameproduct">
            <h4>
              <b>{DESCRICAO}</b>
            </h4> 
          </div>
          {(usuario?.permissao === "superUser" || usuario.permissao === "vendedor" )? <p style={{textAlign: 'left', fontSize:15}}> {convertetobrl(preco_venda)}</p> :""}
          <div className="add__btn" onClick={addItem} style={{cursor: 'pointer'}}>Orçamento</div>
        </div>
      </div>
    </div>
  );
}
