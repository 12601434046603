import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { MenuLeft } from '../../components/MenuLeft';
import { PedidosComponent } from '../../components/Pedidos';

import './pedidos.css'

export function Pedidos() {
  const history = useHistory();

  const { usuario } = useUsuarios();

  useEffect(() => {
    if(!usuario){
      history.push('/')
    }
    return () => {  
    }
  }, [usuario, history])

  return(
    <>
    <Header/>
        <div id="page-pedidos">
          <div id="lateral">
            {usuario?.permissao === 'superUser' &&
              <MenuLeft/>
            }
          </div>
          <div id="principal-pedidos">
            <h5>Pedidos</h5>
            {usuario?.permissao === 'superUser' &&
              <PedidosComponent/>
            }            
          </div>
        </div>
    </>
  );
}

