import React from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/logo.png'

import './headercadastro.css'

export function HeaderCadastro() {

  const history = useHistory();

  function handleGoToHome(){
    history.push('/home')
  }

  function handleGoToLogin(){
    history.push('/')
  }

  return( 

    <div className="container-fluid" id="header">  
        <div className="row">
          <div className="col-lg-4 col-sm-8 col-sx-10" id="logommv">
            <img src={logo} alt="logo MMV" onClick={handleGoToHome} />

            <div id="title" style={{cursor: 'pointer'}} onClick={handleGoToHome}>
            <strong>Catálogo de Produtos<br/>MMV Distribuidora</strong>
            </div>
            
          </div>
          <div className="col-lg-4 col-sm-1" id="meio">
          </div>
          <div className="col-lg-4 col-sm-3 col-sx-2" id="logout">
            <p onClick={handleGoToLogin} style={{color: 'white', cursor: 'pointer', marginRight:30}}>LOGIN</p>
          </div>            
        </div>
    </div>

  );
}
