import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { database } from '../../services/firebase';

import api from '../../services/api';

import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { SliderHome } from '../../components/SliderHome';

import { MenuLeft } from '../../components/MenuLeft';
import { MenuLeftCliente } from '../../components/MenuLeftCliente';

import { PedidosCount } from '../../components/home/PedidosCount';
import { AprovadosCount } from '../../components/home/AprovadosCount';
import { PendentesCount } from '../../components/home/PendentesCount';
import { ExcluidosCount } from '../../components/home/ExcluidosCount';

import { ClientesCount } from '../../components/home/ClientesCount';
import { VendedoresCount } from '../../components/home/VendedoresCount';
import { CategoriasCount } from '../../components/home/CategoriasCount';
import { ProdutosCount } from '../../components/home/ProdutosCount';
import { OrcamentosCount } from '../../components/home/OrcamentosCount';

import { FaBarcode, FaTasks, FaRegFileAlt, FaFileExcel, FaRegUser, FaMoneyBillWave, FaRegIdBadge, FaServer } from 'react-icons/fa';

import './home.css';

export function Home() {

  const history = useHistory();
  const { usuario, handleLogoutSubmit } = useUsuarios();

  const [msgbkp, setMsgBkp] = useState('')

  function handleGoToClientes(){
    history.push('/clientes')
  }
  function handleGoToVendedores(){
    history.push('/vendedores')
  }
  function handleGoToCategorias(){
    history.push('/categorias')
  }

  function handleGoToPedidos(routeName) {
    history.push({
      pathname: '/pedidos',
      state: { statusdopedido: routeName }
    });
  }
  function handleGoToProdutos(routeName) {
    history.push({
      pathname: '/produtos',
      state: { nomedacategoria: routeName }
    });
  }

  const timestamp = new Intl.DateTimeFormat('pt-BR').format(new Date());
  const ultimoLoginUsuarioRef = database.ref(`/usuarios/${usuario?.uid}/ultimologin`);
  useEffect(() => {
    if(usuario?.uid){
      ultimoLoginUsuarioRef.set(timestamp);
    }
    return () => {}
    // eslint-disable-next-line
  }, []);

  async function getCreateBkpSql() {
    const response = await api.get(`/bkp`);
      setMsgBkp(response.data);
  };
 
  // useEffect(() => {
  //   let isSubscribed = true;
  //   async function getDiaDoBkp() {
  //     const response = await api.get(`/diadobkp`);
  //     if (isSubscribed) {
  //       setDiaDoBkp(response.data);
  //     }
  //   }
  //   getDiaDoBkp();
  //   return () => isSubscribed = false
  //   // eslint-disable-next-line
  // }, [msgbkp]);



  return(
    <> 
      <Header/>

      <div id="page-home">

          <div id="lateral">
            {usuario?.permissao === 'superUser' ? <MenuLeft/> : usuario?.analise === true && <MenuLeftCliente/>}
          </div>

          {(usuario?.permissao === 'cliente' || usuario?.permissao === 'vendedor') && usuario?.analise === true &&
            <div id="principal-banner">
              <SliderHome/>
            </div>
          }

          {(usuario?.analise === false || usuario?.analise === 'removido') && 
          <div id="principal-home">
              <div id="page-home-analise">
                <h5>Seu cadastro está em análise!<br/>volte novamente mais tarde...</h5>
                <button className="btnViewCatalog" 
                  onClick={handleLogoutSubmit}>
                  SAIR
                </button>
              </div>
          </div>}          

          {usuario?.permissao === 'superUser' &&
          <div id="principal-home">
            <div className="containerboxhome">
                <div className="boxhome" onClick={handleGoToClientes}>
                  <h1><FaRegUser/></h1>
                  <ClientesCount/>
                </div>
                <div className="boxhome" onClick={handleGoToVendedores}>
                  <h1><FaRegIdBadge/></h1>
                  <VendedoresCount/>
                </div>
                <div className="boxhome" onClick={handleGoToCategorias}>
                  <h1><FaTasks/></h1>
                  <CategoriasCount/> 
                </div>
                <div className="boxhome" onClick={()=>{handleGoToProdutos(null)}}>
                  <h1><FaBarcode/></h1>
                  <ProdutosCount/>
                </div>
                <div className="boxhome" onClick={()=>{handleGoToPedidos('Aprovado')}}>
                  <h1 style={{color:'#3c8b0f'}}><FaRegFileAlt/></h1>
                  <AprovadosCount/>
                </div>
                <div className="boxhome" onClick={()=>{handleGoToPedidos('Pendente')}}>
                  <h1 style={{color:'#f90'}}><FaRegFileAlt/></h1>
                  <PendentesCount/>
                </div>
                <div className="boxhome" onClick={()=>{handleGoToPedidos('Excluido')}} >
                  <h1 style={{color:'#f00'}}><FaFileExcel/></h1>
                  <ExcluidosCount/>
                </div>
                <div className="boxhome" onClick={()=>{handleGoToPedidos('Pendente')}}>
                  <h1><FaRegFileAlt/></h1>
                  <PedidosCount/>
                </div>
                <div className="boxhome">
                  <h1><FaMoneyBillWave/></h1>
                  <OrcamentosCount/>
                </div>

                <div className="boxhome" onClick={getCreateBkpSql}>
                  <h1><FaServer/></h1>                  
                  <p>Realizar Back-Up</p>
                  <p>{msgbkp}</p>
                </div>
            </div>
          </div>
          }
               
      </div>
  </>
    
  );
}
