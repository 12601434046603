import React from 'react';
import { useUsuarios } from '../../contexts/UsuarioContext';

import { Header }  from '../../components/Header';
import { MenuLeft } from '../../components/MenuLeft';
import { CadastroVendedores } from '../../components/CadastroVendedores';

import './cadastrodevendedores.css'

export function CadastroDeVendedores(){
  const { usuario } = useUsuarios();

  return(
    
    <>
    <Header/>
      <div id="page-cadastrodevendedores">

          <div id="lateral">
            {usuario?.permissao === 'superUser' && <MenuLeft/>}
          </div>

          <div id="principal-cadastrodevendedores">
            {usuario?.analise === false && 
            <>
              <h5>Seu cadastro está em análise,<br/>
              volte novamente mais tarde...</h5>              
            </>
            }

            {usuario?.permissao === 'superUser' &&           
              <CadastroVendedores/> 
            }
          </div>

      </div>

    </>

  )
}