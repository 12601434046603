import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { useAuth } from "../../contexts/AuthContext";
import { useUsuarios } from '../../contexts/UsuarioContext';
import { Header }  from '../../components/Header';
import { MenuLeft } from '../../components/MenuLeft';
import './obrigado.css'

export function Obrigado() {
  const location = useLocation();
  const history = useHistory();
  
  const { currentUser } = useAuth();
  const { usuario } = useUsuarios();

  const [ pedidoconfirmado, setPedidoConfirmado ] = useState(0);

  useEffect(() => {
    setPedidoConfirmado(location.state.pedidoconfirmado);

    confirmAlert({
      title: `Pedido ${pedidoconfirmado}`,
      message: `realizado com sucesso.`,
      buttons: [
        {
          label: "OK",
          onClick: () => {
            if(usuario.permissao === 'superUser'){
              history.push('/pedidos')
            }
            if(usuario.permissao === 'cliente'){
              history.push('/pedidoscliente')
            }
            if(usuario.permissao === 'vendedor'){
              history.push('/pedidosvendedor')
            }
          }
        }
      ]
    });

 }, [ location, pedidoconfirmado, history, usuario ]);

  if(!currentUser){
    history.push('/')
  }


  return(
    <>
      <Header/>
      <div id="page-obrigado">
        <div id="lateral">
        <MenuLeft/>
        </div>
        <div id="principal-obrigado">
          
        </div>
      </div>
    </>
  );
}

