import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import { database } from '../../services/firebase';
import { useAuth } from "../../contexts/AuthContext";
import { useCount } from '../../contexts/CountContext';

import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import './cadastrocliente.css'

export function CadastroCliente() {

  const history = useHistory();
  const { signup } = useAuth();
  const { codigocliente } = useCount();


 // eslint-disable-next-line
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [erroemail, setErroEmail] = useState('');
  const [password, setPassword] = useState('');
  const [erropassword, setErroPassword] = useState('');

  const [cnpj, setCnpj] = useState('');
  const [erroCnpj, setErroCnpj] = useState('');
   // eslint-disable-next-line
  const [mask, setMask] = useState('');

  const [dadosempresa, setDadosEmpresa] = useState('');
  const [razaosocial, setRazaosocial] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [telefone, setTelefone] = useState('');
  const [errotelefone, setErroTelefone] = useState('');

  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [cep, setCep] = useState('');

  const [nome, setNome] = useState('');
  const [erronome, setErroNome] = useState('');
  const [cargo, setCargo] = useState('');
  const [errocargo, setErroCargo] = useState('');
  const [celular, setCelular] = useState('');
  const [errocelular, setErroCelular] = useState('');

  const [desabilitaconsulta, setDesabilitaConsulta] = useState(false);

  const timestamp = new Intl.DateTimeFormat('pt-BR').format(new Date());

   function validateCNPJ(){
    if(cnpj === ''){
      setErroCnpj('digite o CNPJ e clique em ok')
      return;
    }else if(cnpj.length < 14){
      setErroCnpj('digite um CNPJ corretamente')
      return;
    }else{
      setErroCnpj('')
    } 
   }

   function validatePassword(){
    if(password.length < 8 ){
      setErroPassword('mínimo 8 dígitos')
      return;
    }else{
      setErroPassword('')
    } 
   }

   function validateNome(){
    if(nome.length < 3 ){
      setErroNome('campo obrigatório')
      return;
    }else{
      setErroNome('')
    } 
   }

   function validateCargo(){
    if(cargo.length < 3 ){
      setErroCargo('campo obrigatório')
      return;
    }else{
      setErroCargo('')
    } 
   }

   function validateTelefone(){
    if(telefone?.length < 6 ){
      setErroTelefone('campo obrigatório')
      return;
    }else{
      setErroTelefone('')
    } 
   }

  // (00) 00000-0000
  const maskPhone = value => {
    return value
      .replace(/\D/g,'')
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d{4})/, "$1-$2")
  };
          
  function validadeCelular(){
    const regex = new RegExp(/^(?:(?:\+|00)?(55)\s?)?(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/); 
    if (regex.test(celular)) { 
      console.log("Telefone Válido");
      setErroCelular('')
    }
    else setErroCelular("número inválido");
  }

  function ValidateEmail() {

   if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email))
    { setErroEmail('')
    }else{
      setErroEmail('digite um e-mail válido!')
    }
  }

  async function consultaCNPJ(cnpjreplace) { 

    if(cnpj !== ''){      
      setDesabilitaConsulta(true);
    }

    if(cnpj === ''){
      alert('Digite o CNPJ e clique em Ok');
      return;
    }

    function jsonp(url, timeout) {
       // Gera um nome aleatório para a função de callback
       const func = 'jsonp_' + Math.random().toString(36).substr(2, 5);
    
       return new Promise(function(resolve, reject) {
             // Cria um script
             let script = document.createElement('script');      
             // Cria um timer para controlar o tempo limite            
             let timer = setTimeout(() => {
                reject('Tempo limite atingido');
                document.body.removeChild(script);
             }, timeout);      
             // Cria a função de callback
             window[func] = (json) => {
                clearTimeout(timer);
                console.log(timer)
                resolve(json);
                document.body.removeChild(script);
                delete window[func];
             };      
             // Adiciona o script na página para inicializar a solicitação
             script.src = url + '?callback=' + encodeURI(func);
             document.body.appendChild(script);
       });
    }
    // Limpa o CNPJ para conter somente numeros, removendo traços e pontos
    cnpjreplace = cnpj.replace(/\D/g, '');

    if (cnpj.length < 14) return Promise.reject('CNPJ inválido');

    // Consulta o CNPJ na ReceitaWS com 60 segundos de tempo limite
    return jsonp('https://www.receitaws.com.br/v1/cnpj/' + encodeURI(cnpjreplace), 600000)
       
       .then(async (json) => {
          if (json['status'] === 'ERROR') { 
             return Promise.reject(json['message'], setErroCnpj(json['message']))  
                      
          } else {                                         
             const json_1 = await Promise.resolve(json);
            setDadosEmpresa(json_1);               
          }                  
       }); 
          
  }


    async function handleSubmit(event) {

        event.preventDefault()

        try {
          setError("")
          setLoading(true)   
              
          if (
            nome === '' ||
            celular === '' ||
            email === ''||
            password === '' ||
            cnpj === '' ||
            razaosocial === '' ||
            logradouro === ''||
            numero === '' ||
            bairro === ''||
            cidade === ''||
            estado === '' ||
            cep === ''
          ){
          alert('Digite os campos corretamente...');
          setLoading(false);
          return;  

        } else {

          const newCliente = await signup(email, password);

          const newUClienteBd = database.ref(`usuarios/${newCliente.user.uid}/`);
           
          const cliente = { 
              uid: newCliente.user.uid,
              email: newCliente.user.email,
              nome:nome,
              superUser:false,
              permissao:"cliente",
              analise:false,
              createat:timestamp,
              ultimologin:"nunca",

              cod:codigocliente + 1,
              razaosocial:razaosocial,
              cnpj:cnpj,
              endereco:{
                logradouro:logradouro,
                complemento:complemento,
                numero:numero,
                bairro:bairro,
                cidade:cidade,
                estado:estado,
                cep:cep,
              },   
              telefone:telefone,
              celular:celular,
              cargo:cargo,
          }

          await newUClienteBd.set(cliente);  
          await database.ref('codigocliente/').set(codigocliente + 1);

          setLoading(false);  
          
          history.push({
            pathname: '/obrigadocadastro',
            state: { cadastrodocliente: nome}
          });
              
        }

        }catch(e){
          switch(e.code){
            case 'auth/email-already-in-use':
              alert('Este email já está sendo utilizado');
              
              setEmail('')
              break;

            case 'auth/invalid-email':
              alert('Email Inválido! Tente outro');
              setEmail('')
              break;

            default:
          }
        //https://stackoverflow.com/questions/53129814/show-error-as-alert-on-firebase-authentication-with-react-native
        setLoading(false);
        }
    
    }


  useEffect(() => {

    let t = setTimeout(() => {
      setRazaosocial(dadosempresa?.nome);
      setLogradouro(dadosempresa?.logradouro);
      setNumero(dadosempresa?.numero);
      setBairro(dadosempresa?.bairro);
      setCep(dadosempresa?.cep);
      setEstado(dadosempresa?.uf);
      setCidade(dadosempresa?.municipio);
      setTelefone(dadosempresa?.telefone);
    }, 1000);

    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [consultaCNPJ]);


  useEffect(() => {
    let t = setTimeout(() => {
      consultaCNPJ();
      setDesabilitaConsulta(false)
    }, 30 * 1000);

    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [desabilitaconsulta]);

  //console.log(desabilitaconsulta)

  function aguarde(){
    alert('Aguarde para consultar novamente.');
    return;
  }

 //console.log(dadosempresa);

 //console.log(`dados da empresa: ${razaosocial} ${logradouro} ${numero} ${bairro} ${cep} ${cidade} ${estado} ${telefone}`)

    
  return(
    <Container fluid>

      {loading && <div className="aguarde"> <h1>...</h1> </div>}

        <form onSubmit={handleSubmit}>

        {!loading && <>

            <div className="row" style={{borderBottom: '1px dashed #d5d5d5', marginBottom:20,  paddingBottom:20}}> 
            {/* <p> 60049434000108 | 07786144000164 </p> */}

                  <div className="col-sm-3">
                      <div id="inputcreate">
                          <p><span style={{color:'red'}}>* </span>Seu nome</p>    
                          <input 
                            type="text" 
                            placeholder="digite seu nome"
                            onChange={event => setNome(event.target.value)}
                            onBlur={validateNome}
                            value={nome}
                          />
                          <p><span style={{color:'red'}}> {erronome} </span></p> 
                      </div>
                  </div>
                  <div className="col-sm-2">
                      <div id="inputcreate">
                        <p>Cargo</p>    
                          <input 
                            type="text" 
                            placeholder="cargo"
                            // onBlur={validateCargo}
                            onChange={event => setCargo(event.target.value)}
                            value={cargo}
                          />
                          <p><span style={{color:'red'}}> {errocargo} </span></p>
                      </div>
                  </div>
                  <div className="col-sm-2" >
                      <div id="inputcreate">
                        <p><span style={{color:'red'}}>* </span>Celular</p>    
                        <input 
                          type="text" 
                          placeholder="(xx) 99999-0000"
                          onBlur={validadeCelular}
                          maxLength="14"
                          onChange={event => setCelular(maskPhone(event.target.value))}
                          value={celular}
                        />                       
                      <p><span style={{color:'red'}}> {errocelular} </span></p> 
                      </div>
                  </div>
                  <div className="col-sm-3" >
                      <div id="inputcreate">
                          <p><span style={{color:'red'}}>* </span>Seu melhor e-mail</p>    
                          <input 
                            type="text" 
                            placeholder="ex: email@empresa.com.br"
                            onChange={event => setEmail(event.target.value)}
                            onBlur={ValidateEmail}                          
                            value={email}
                            width="100%"
                          />
                          <p><span style={{color:'red'}}> {erroemail} </span></p> 
                      </div>
                  </div>
                  <div className="col-sm-2" >
                      <div id="inputcreate">
                          <p><span style={{color:'red'}}>* </span>Senha</p>    
                          <input 
                            type="password"
                            placeholder="min. 8 dígitos"
                            onChange={event => setPassword(event.target.value)}
                            onBlur={validatePassword}
                            value={password}
                            //autoCorrect={false}
                            minLength="8"
                            width="100%"
                            autoComplete="new-password"
                          />
                          <p><span style={{color:'red'}}> {erropassword} </span></p> 
                      </div>
                  </div>
              </div>

                <div className="row">
                  <div className="col-sm-2" >
                      <div id="inputcreate">
                        {/* <p>CNPJ {erroCnpj? <span style={{color: 'red'}}>{erroCnpj}</span> : ""}</p>    */}
                        <p><span style={{color:'red'}}>* </span>CNPJ</p>
                        <CpfCnpj
                          value={cnpj}
                          onBlur={validateCNPJ}
                          placeholder="digite o CNPJ"
                          onChange={(event, type) => {
                            setCnpj(event.target.value);
                            setMask(type === "CNPJ");
                          }}
                        />
                        <p><span style={{color:'red'}}> {erroCnpj} </span></p> 
                      </div>
                  </div>

                {desabilitaconsulta &&
                  <div className="col-sm-1" id="btnconsultarenable">
                    <p> </p>
                    <button 
                    type="button"                 
                    onClick={aguarde} 
                    disabled={false} > Ok 
                    </button>  
                  </div> 
                }               

                {!desabilitaconsulta &&
                  <div className="col-sm-1" id="btnconsultar">
                    <p> </p>
                  <button 
                    type="button"                 
                    onClick={consultaCNPJ} 
                    disabled={false}> Ok 
                  </button> 
                  </div>
                }

                <div className="col-sm-3" >
                    <div id="inputcreate">
                      <p>Razão Social</p>    
                      <input type="text"  
                        disabled = {true} 
                        placeholder="digite o CNPJ para autocompletar a razão social"
                        onChange={event => setRazaosocial(event.target.defaultValue)}
                        defaultValue={dadosempresa?.nome ? dadosempresa?.nome : razaosocial}                          
                      />
                    </div>
                </div>
                <div className="col-sm-3" >
                  <div id="inputcreate">
                    <p>Endereço</p>    
                      <input type="text" 
                        placeholder="digite o CNPJ para autocompletar o endereço"
                        disabled = {true}
                        onChange={event => setLogradouro(event.target.defaultValue)}
                        defaultValue={dadosempresa?.logradouro ? dadosempresa?.logradouro : logradouro}
                      />
                  </div>
                </div>
                <div className="col-sm-1" >
                  <div id="inputcreate">
                    <p>Número</p>    
                    <input  type="text" 
                      placeholder="número"
                      disabled = {true}
                      onChange={event => setNumero(event.target.defaultValue)}
                      defaultValue={dadosempresa?.numero ? dadosempresa?.numero : numero}
                    />
                  </div>
                </div>
                <div className="col-sm-2" >
                    <div id="inputcreate">
                      <p>Telefone Fixo</p>    
                      <input type="text" 
                        placeholder="telefone fixo"
                        disabled = {true}
                        // onBlur={validateTelefone}
                        onChange={event => setTelefone(event.target.defaultValue)}
                        defaultValue={dadosempresa?.telefone ? dadosempresa?.telefone : ''}
                      />
                      <p><span style={{color:'red'}}> {errotelefone} </span></p> 
                    </div>
                  </div>
              </div>
              <div className="row" style={{borderBottom: '1px dashed #d5d5d5', marginBottom:20, paddingBottom:20}}>
                <div className="col-sm-2" >
                  <div id="inputcreate">
                    <p>Complemento</p>    
                    <input type="text" 
                      placeholder="complemento" 
                      disabled = {true}
                      onChange={event => setComplemento(event.target.defaultValue)}
                      defaultValue={dadosempresa?.complemento ? dadosempresa?.complemento : complemento}
                    />
                    <p><span style={{color:'red'}}> {} </span></p> 
                  </div>
                </div>
                <div className="col-sm-4" >
                  <div id="inputcreate">
                    <p>Bairro</p>    
                    <input type="text" 
                      placeholder="digite o CNPJ para autocompletar o bairro" 
                      disabled = {true}
                      onChange={event => setBairro(event.target.defaultValue)}
                      defaultValue={dadosempresa?.bairro ? dadosempresa.bairro: bairro}
                    />
                  </div>
                </div>
                <div className="col-sm-3" >
                    <div id="inputcreate">
                      <p>Cidade</p>    
                      <input type="text" 
                        placeholder="digite o CNPJ para autocompletar a cidade" 
                        disabled = {true}
                        onChange={event => setCidade(event.target.defaultValue)}
                        defaultValue={dadosempresa?.municipio ? dadosempresa.municipio: cidade}
                      />
                    </div>
                </div>
                <div className="col-sm-1" >
                    <div id="inputcreate" style={{ maxWidth:'100%'}}>
                      <p>Estado</p>    
                      <input type="text"
                        placeholder="UF"
                        disabled = {true}
                        onChange={event => setEstado(event.target.defaultValue)}
                        defaultValue={dadosempresa?.uf ? dadosempresa.uf : estado}
                        length={2}
                      />
                    </div>
                </div>
                <div className="col-sm-2" >
                    <div id="inputcreate">
                      <p>Cep</p>    
                      <input 
                        type="text" 
                        placeholder="CEP"  
                        disabled = {true}                   
                        onChange={event => setCep(event.target.defaultValue)}
                        defaultValue={dadosempresa?.cep ? dadosempresa.cep: cep}                          
                      />
                    </div>
                </div>
              </div>

            <div id="btnCriarConta">
              <button
                type="submit" 
                disabled={loading} 
                className="btnCriarConta" 
                style={{width: '100%'}}>
                CADASTRAR
              </button>    
            </div>

          </>
        }

        </form>

    </Container>
  )
}
