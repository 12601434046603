import React from 'react';

import { HeaderCadastro }  from '../../components/HeaderCadastro';
import { CadastroCliente } from '../../components/Cadastro';
import './cadastroexterno.css'

export function CadastroExterno() {

  return(
    <>
    <HeaderCadastro/>
        <div id="page-cadastro-externo">
          <div id="sidebar">
          
          </div>
          <div id="principal-cadastro-externo">
          <h5>Criar Conta</h5>
            <CadastroCliente/>
          </div>
        </div>
    </>
  );
}
