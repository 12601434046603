import React, { useContext, useState, useEffect } from "react"

import api from '../services/api';

const CategoriaContext = React.createContext()

export function useCategorias() {
  return useContext(CategoriaContext);
}

export function CategoriaProvider({ children }) {

  const [categoriasData, setCategoriasData] = useState([]);

  useEffect(() => {
    let isSubscribed = true
    async function getCategoriasSql() {
      const response = await api.get('/categorias', { 
        body: JSON.stringify(categoriasData)
      });
      if (isSubscribed) {
        setCategoriasData(response.data);
      }        
    };
    getCategoriasSql();
    return () => isSubscribed = false
    // eslint-disable-next-line 
  }, []);


  categoriasData.sort(function (a, b) {
    return (a.DESCRICAO > b.DESCRICAO) ? 1 : ((b.DESCRICAO > a.DESCRICAO) ? -1 : 0);
  });

  const value = {
    categoriasData,
  }

  // console.log(categoriasData)

  return (
    <CategoriaContext.Provider value={value}>
      {children}
    </CategoriaContext.Provider>
  )

}