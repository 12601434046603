import React from 'react';
import { useUsuarios } from '../../contexts/UsuarioContext';

export default function Item({ setProductsCount, setShoppingList, shoppingList, item }) {

  const { usuario } = useUsuarios();
  
  const { CD_PRODUTO, DESCRICAO, preco_venda, quantity } = item;

  const removeItem = () => {
    setProductsCount(productCount => productCount - 1);
    let product_array = shoppingList.filter(p => p.CD_PRODUTO === CD_PRODUTO);
    if (product_array[0].quantity === 1) {
      setShoppingList(shoppingList.filter(p => p.CD_PRODUTO !== CD_PRODUTO));
    } else {
      let new_state = shoppingList.map(p => {
        if (p.CD_PRODUTO === CD_PRODUTO) {
          p.quantity -= 1;
          return { ...p };
        } else return { ...p };
      });
      setShoppingList(new_state);
    }
  };

  const addItem = () => {
    setProductsCount(productCount => productCount + 1);
    let new_state = shoppingList.map(p => {
      if (p.CD_PRODUTO === CD_PRODUTO) {
        p.quantity += 1;
        return { ...p };
      } else return { ...p };
    });
    setShoppingList(new_state);
  };

  const deleteAllItems = () => {
    let totalQuantity = 0;
    const filtered_shoppingList = shoppingList.filter(p => {
      if (p.CD_PRODUTO !== CD_PRODUTO) {
        totalQuantity += p.quantity;
        return p;
      } else return null;
    });
    setProductsCount(totalQuantity);
    setShoppingList(filtered_shoppingList);
  };

  function financial(x) {
    return Number.parseFloat(x).toFixed(2);
  }
  
  return (
    <div className="bag__item__container">
      <div className="item__close__btn__wrapper">
        <div className="item__close__btn" onClick={deleteAllItems}>
          X
        </div>
      </div>
      <div className="bag__item">
        <div>
        <span style={{ marginTop:-20, }}>Cód: <strong style={{ color: '#da9d54'}}>{CD_PRODUTO}</strong></span>
        <span style={{ width: '90%', lineHeight:1, paddingBottom:20, userSelect: 'none'}}>{DESCRICAO}</span>
        </div>
        <div>
          {(usuario?.permissao === "superUser" || usuario?.permissao === "vendedor") &&
          <span className="bag__price" style={{ width:80, color: '#afec6a', userSelect: 'none', textAlign: 'rigth' }}>
            R$ {financial(preco_venda)}
          </span>
          }
        </div>
      </div>
      <div className="bag__operation">
        <span style={{ fontSize:15, userSelect: 'none'}}>Quantidade: {quantity}</span>
        <div>
          <div className="small__btn" onClick={removeItem}>
            -
          </div>
          <div className="small__btn" onClick={addItem}>
            +
          </div>
        </div>
      </div>
    </div>
  );
}
