import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useUsuarios} from '../../contexts/UsuarioContext';
import { Header }  from '../../components/Header';

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import './obrigadocadastrovendedor.css'

export function ObrigadoCadastroVendedor() {

  const location = useLocation();
  const history = useHistory();
  const { handleLogoutSubmit } = useUsuarios();

  const [cadastroconfirmado, setCadastroConfirmado] = useState(0);

  useEffect(() => {

    setCadastroConfirmado(location?.state?.cadastrodovendedor);

    confirmAlert({
    title: `Cadastro!`,
    message: `Cadastro de ${cadastroconfirmado} realizado`,
    buttons: [
      {
        label: "Home",
        onClick: () => {}
      }
    ]
    });

  return () => {console.log('obrigado Cadastro Vendedor')}

  }, [ location, cadastroconfirmado, history ]);



  return(
    <>
     <Header/>
      <div id="page-obrigadovendedor">
        <div id="lateral">
        </div>
        <div id="principal-obrigadovendedor">
          <p>Obrigado por cadastrar um vendedor.<br/> Para continuar, faça Logout!</p> 
           <button className="btnViewCatalog" onClick={handleLogoutSubmit}>SAIR</button>
        </div>
      </div>
    </>
  );
}

