import React, { createContext, useState } from 'react';
export const ShoppingListContext = createContext();

export const ShoppingListProvider = ({ children }) => {
  
  const [productsCount, setProductsCount] = useState(
    JSON.parse(localStorage.getItem('prod_count')) || 0
  );

  const [shoppingList, setShoppingList] = useState(
    JSON.parse(localStorage.getItem('shopping_list')) || []
  );
  
  const [cartIsOpen, setCartIsOpen] = useState(false);

  return (
    <ShoppingListContext.Provider value={{
        shoppingList,
        productsCount,
        setProductsCount,
        setShoppingList,
        cartIsOpen,
        setCartIsOpen
      }}>
      {children}
    </ShoppingListContext.Provider>
  );
};
